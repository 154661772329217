//  [REDUCER][TYPE]
import { typeCreatorV2 } from 'redux/utils/common';

// export const SET_SCHOOL_APPLICATIONS: string = typeCreatorV2(
//   'ApplicationSchoolListReducer',
//   'SetSchoolApplications'
// );

export const SET_SCHOOL_FILES: string = typeCreatorV2(
  'ApplicationSchoolListReducer',
  'SetSchoolFiles'
);
