//  [REDUCER][TYPE]
import { typeCreatorV2 } from 'redux/utils/common';

export const SET_SCHOOL_APPLICATIONS: string = typeCreatorV2(
  'SchoolDetailsReducer',
  'SetSchoolApplications'
);

export const SET_SCHOOL_FILES: string = typeCreatorV2(
  'SchoolDetailsReducer',
  'SetSchoolFiles'
);

export const GET_SCHOOL_BASIC_DATA: string = typeCreatorV2(
  'SchoolDetailsReducer',
  'GetSchoolData'
);

export const GET_SCHOOL_TEACHER_LIST: string = typeCreatorV2(
  'SchoolDetailsReducer',
  'GetSchoolTeachers'
);

export const GET_SCHOOL_ADDRESS: string = typeCreatorV2(
  'SchoolDetailsReducer',
  'GetSchoolAddress'
);

export const UPDATE_SCHOOL: string = typeCreatorV2(
  'SchoolDetailsReducer',
  'UpdateSchool'
);

export const REASSIGN_USER: string = typeCreatorV2(
  'SchoolDetailsReducer',
  'ReassignUser'
);

export const GET_INDICTMENT_LIST: string = typeCreatorV2(
  'SchoolDetailsReducer',
  'GetIndictmentList'
);
