import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // height: '75vh',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    // backgroundColor: 'red',
  },
}));

export default useStyles;
