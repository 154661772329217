// eslint-disable-next-line
import React, { CSSProperties, ReactElement, useState } from 'react';
import { Grid, Button, Icon, Paper, Typography, Box } from '@material-ui/core';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import useStyles from './styles';
import { useForm } from 'react-hook-form';
import NoImage from '@material-ui/icons/BrokenImageOutlined';
import { axios } from 'utils/request';
import { useDispatch } from 'react-redux';
import ModalActions from 'redux/reducers/gl_modal_reducer/actions';
import { ModalIDs } from 'redux/reducers/gl_modal_reducer/types';
import downloadjs from 'downloadjs';
import { FilePreview } from './FilePreview';
import { UploadButton } from './UploadButton';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import TranslatedText from 'components/atoms/translated_text';
import LanguageEnum from 'config/languages';

export enum FileAcceptType {
  documents,
  images,
  all,
}
interface Iprops {
  rules: any;
  variant?: 'outlined' | 'contained';
  margin?: 'none' | 'dense' | 'normal';
  size?: 'small' | 'medium';
  name: string;
  label: string | React.ReactElement;
  register: ReturnType<typeof useForm>['register'];
  setValue: ReturnType<typeof useForm>['setValue'];
  watch: ReturnType<typeof useForm>['watch'];
  error?: boolean;
  helperText?: React.ReactElement | string;
  fullWidth?: boolean;
  fileInitialValues?: IFileInitialValues;
  multiple?: boolean;
  disabled?: boolean;
  renderButton?: boolean;
  acceptType?: FileAcceptType;
  style?: CSSProperties;
  showWarning_1?: boolean;
  showWarning_2?: boolean;
  onChange?: (files: IUploadedFile[]) => void;
}

export type IFileInitialValues = Array<{
  url: string;
  fileName: string;
}>;

export type IUploadedFiles = Array<IUploadedFile>;
export type IUploadedFile = {
  src: string | null;
  isImage: boolean;
  fileName: string;
  url: string;
};
const ERROR_COLOR = '#f44336';
const usePreviewer = false;

export function downloadFileFrom(filepath: string) {}

export default function UploadPicker({
  rules,
  setValue,
  fullWidth,
  variant,
  label,
  name,
  helperText,
  error,
  fileInitialValues,
  size,
  multiple,
  margin,
  register,
  disabled = false,
  watch,
  renderButton = true,
  acceptType = FileAcceptType.all,
  style,
  showWarning_1,
  showWarning_2,
  onChange,
}: Iprops) {
  const hiddenFileInput = React.useRef<any>(null);
  const classes = useStyles({ margin: margin === 'normal' });
  const uploadedFiles: IUploadedFiles = fileInitialValues
    ? fileInitialValues
    : watch(name) || [];
  // console.log(uploadedFiles, multiple);

  const dispatch = useDispatch();

  const removeFile = React.useCallback(
    (_fileName) => {
      const files = uploadedFiles.filter(
        ({ fileName }) => _fileName !== fileName
      );
      // console.log('File data::', hiddenFileInput.current.value);

      // setUploadedFiles([...files]);
      const _files = [...files];
      // console.log('Set values:', files);

      setValue(name, _files.length > 0 ? _files : null);
      hiddenFileInput.current.value = _files;
      //_files.map((el) => el.fileName);
    },
    [uploadedFiles, setValue]
  );

  const downloadPDF = (fileUrl: string, fileName: string) => {
    console.log(fileUrl);
    axios({
      url: fileUrl,
      onDownloadProgress(data) {
        // console.log(
        //   'download progress:',
        //   (data.loaded / data.total) * 100 + '%'
        // );
      },
      // responseType: 'blob',
    }).then((response) => {
      var data = response.data;
      if ('TextEncoder' in window) {
        var enc = new TextEncoder(); // always utf-8
        data = enc.encode(data);
      } else {
        console.error('Encoder not supported by browser');
      }
      // downloadjs(response.data, fileName, 'application/pdf');
      // console.log('~/Downloads/' + fileName);
      dispatch(
        ModalActions.openModalAction({
          modalID: ModalIDs.MODAL_FILE_PREVIEW,
          params: {
            file: {
              data: data,
            },
            onDownload: () => {
              downloadjs(response.data, fileName, 'application/pdf');
            },
          },
        })
      );
    });
  };

  const downloadFile = React.useCallback(
    async (uploadedFile: IUploadedFile) => {
      try {
        let url: string | null = uploadedFile.src
          ? window.URL.createObjectURL(
              new Blob([uploadedFile.src as any], {
                type: 'application/octet-stream',
              })
            )
          : null;

        let fileData = await fetch(uploadedFile.url || '');
        let blob = await fileData.blob();
        if (!uploadedFile.src) {
          url = URL.createObjectURL(blob);
        }
        // console.log(url);

        if (!usePreviewer) {
          // console.log(uploadedFile.url);
          if (uploadedFile.url) {
            console.log(uploadedFile.url);
            window.open(uploadedFile.url, uploadedFile.fileName);
          }
        } else {
          if (uploadedFile.fileName?.endsWith('.pdf')) {
            downloadPDF(url || '', uploadedFile.fileName);
          } else {
            var a = document.createElement('a');
            a.href = url || '';
            a.download = uploadedFile.fileName;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove();
          }
        }

        // console.log(uploadedFile.url);
        // console.log(url);
      } catch (error) {}
    },
    []
  );

  const handleClick = (event: any) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event: any) => {
    const files: any = event?.target?.files || [];
    // console.log('Files::', files);

    const filesArr = multiple ? [...uploadedFiles] : [];
    for (let fileInput of files) {
      filesArr.push({
        src: fileInput, //URL.createObjectURL(),
        isImage: Boolean(fileInput.name.match(/.(jpg|jpeg|png|gif)$/i)),
        fileName: fileInput.name,
        url: URL.createObjectURL(fileInput),
      });
    }
    // setUploadedFiles(filesArr);

    // console.log('Set values:', filesArr);
    if (onChange) onChange(filesArr);
    setValue(name, filesArr);
  };

  return (
    <div className={classes.container} style={style}>
      {showWarning_1 && (
        <Typography>
          <TranslatedText
            textMap={{
              [LanguageEnum.English]:
                'Certificates / certificates that are not overdue and will not expire at least 3 months from the date of application are accepted.',
              [LanguageEnum.Greek]:
                'Γίνονται αποδεκτά πιστοποιητικά / βεβαιώσεις που δεν είναι εκπρόθεσμα και δεν πρόκειται να λήξει η ημερομηνία ισχύς τους τουλάχιστον 1 μήνα από την ημερομηνία υποβολής της αίτησης.',
            }}
          />
        </Typography>
      )}
      {showWarning_2 && (
        <>
          {showWarning_1 && <br />}
          <Typography>
            <TranslatedText
              textMap={{
                [LanguageEnum.English]:
                  'All documents must be in Greek or English. Otherwise, the documents must be submitted with a certified translation into Greek or English.',
                [LanguageEnum.Greek]:
                  'Όλα τα έγγραφα πρέπει να είναι στην ελληνική ή αγγλική γλώσσα. Σε αντίθετη περίπτωση, θα πρέπει τα έγγραφα να υποβληθούν με  πιστοποιημένη μετάφραση   στην ελληνική ή αγγλική γλώσσα.',
              }}
            />
          </Typography>
        </>
      )}
      {(showWarning_1 || showWarning_2) && <br />}

      {renderButton && (
        <Box style={{ display: 'flex' }}>
          <Box flexGrow={multiple ? 0.9 : 1} style={{ margin: 1 }}>
            <UploadButton
              label={label}
              variant={variant}
              size={size}
              disabled={disabled}
              error={error}
              fullWidth={fullWidth}
              icon={<BackupOutlinedIcon style={{ marginRight: '1rem' }} />}
              handleClick={handleClick}
            />
            <input
              className="upload"
              type="file"
              name={name}
              multiple={multiple}
              ref={(data) => {
                hiddenFileInput.current = data;
                register(name, rules);
              }}
              onChange={handleChange}
              style={{ display: 'none' }}
              accept={
                acceptType == FileAcceptType.documents
                  ? '.pdf,.doc,.docx,.txt'
                  : acceptType == FileAcceptType.images
                  ? 'image/*'
                  : undefined
              }
            />
          </Box>
          {multiple && (
            <Box flexGrow={0.1} style={{ margin: 1 }}>
              <UploadButton
                label={
                  <TranslatedText
                    textMap={{
                      [LanguageEnum.English]: 'Clear',
                      [LanguageEnum.Greek]: `Διαγραφή 'Ολων`,
                    }}
                  />
                }
                variant={variant}
                size={size}
                disabled={disabled}
                error={error}
                fullWidth={fullWidth}
                icon={
                  <DeleteOutlineOutlinedIcon style={{ marginRight: '1rem' }} />
                }
                handleClick={() => {
                  hiddenFileInput.current.value = null;
                  setValue(name, []);
                }}
              />
            </Box>
          )}
        </Box>
      )}
      {helperText && (
        <Typography
          style={{
            color: error ? ERROR_COLOR : undefined,
            fontSize: 12,
            marginLeft: '1rem',
            paddingTop: 1,
          }}
        >
          {helperText}
        </Typography>
      )}
      {uploadedFiles.length > 0 ? (
        <div
          style={{
            display: 'flex',
            overflowX: 'auto',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {uploadedFiles?.map &&
              uploadedFiles.map((uploadedFile, index) => {
                // console.log(uploadedFile);
                return (
                  <Paper
                    variant="outlined"
                    key={index}
                    className={classes.imageContainer}
                  >
                    {uploadedFile?.fileName && (
                      <FilePreview
                        disabled={disabled}
                        file={uploadedFile}
                        canRemove={!multiple}
                        onRemove={removeFile}
                        onDownload={downloadFile}
                        margin={margin}
                        size={size}
                      />
                    )}
                    {uploadedFile.src && (
                      <Typography component="h5">
                        {uploadedFile.fileName}
                      </Typography>
                    )}
                  </Paper>
                );
              })}
          </div>
        </div>
      ) : (
        !renderButton && (
          <NoImage
            className={size == 'small' ? classes.small_image : classes.image}
            style={{ color: 'gray' }}
          />
        )
      )}
    </div>
  );
}
