import React from 'react';
import * as AuthSelectors from 'redux/reducers/gl_auth_reducer/selectors';
import AuthActions from 'redux/reducers/gl_auth_reducer/actions';
import { useDispatch } from 'react-redux';
import reactLogger from 'utils/logger';
import { StateStatus } from 'redux/utils/common';
import store from 'redux/store';
import Cookies from 'js-cookie';
import { showToastAction } from 'components/atoms/toast_message';
import _ from 'lodash';
import useQuery from 'custom_hooks/useQuery';

const AuthProvider = ({ children }: any) => {
  reactLogger.renderComponent('AuthProvider');
  const getDataStatus = AuthSelectors.useSelectUserDataStatus();
  const dispatch = useDispatch();
  const query = useQuery();
  const cookiesToken = Cookies.get('token') ?? query.get('token') ?? '';
  const cookiesRefreshToken =
    Cookies.get('refreshToken') ?? query.get('refreshToken') ?? '';
  const error = Cookies.get('error');

  React.useEffect(() => {
    if (error) {
      try {
        const errorData = JSON.parse(error);
        showToastAction(dispatch, _.get(errorData, 'message'), 'error');
      } catch (error) {}
    }
  }, [error, dispatch]);

  const reLogin = Boolean(
    (store.getState().AuthReducer.token &&
      store.getState().AuthReducer.refreshToken) ||
      (cookiesToken && cookiesRefreshToken)
  );

  React.useEffect(() => {
    if (reLogin) {
      dispatch(
        AuthActions.fetchUserDataAction({
          overrideAuth:
            cookiesToken && cookiesRefreshToken
              ? {
                  token: cookiesToken,
                  refreshToken: cookiesRefreshToken,
                }
              : undefined,
        })
      );
    }

    return () => {
      Cookies.remove('token');
      Cookies.remove('refreshToken');
      Cookies.remove('error');
    };
  }, [dispatch, reLogin, cookiesToken, cookiesRefreshToken]);

  if (!reLogin || StateStatus.Success === getDataStatus) {
    return children;
  }

  return null;
};

export default React.memo(AuthProvider);
