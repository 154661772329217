// eslint-disable-next-line
import React, { ReactElement } from 'react';
import { Box, Card } from '@material-ui/core';

interface IProps {
  children: ReactElement;
  height: string | number;
}

export default function MapWrapper({ children, height }: IProps) {
  return (
    <Card>
      <Box height={height} position="relative">
        {children}
      </Box>
    </Card>
  );
}
