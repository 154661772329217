import {
  ITeacherApplicationProfile,
  ITeacherApplicationEducation,
  ITeacherApplicationQualification,
  ITeacherApplicationServices,
  ITeacherApplicationWorkingExperience,
} from 'services/api/application_teacher';
import {
  IAction,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';
import * as Types from './types';
import * as SchoolService from 'services/api/school';
import {
  StatusOption,
  TeacherApplicationStatus,
} from 'config/applicationStatus';
import { ITeacherReview } from '../configuration';
import React from 'react';

export interface IState {
  teacherProfile: ITeacherApplicationProfile | null;
  services: Array<ITeacherApplicationServices>;
  qualifications: Array<ITeacherApplicationQualification>;
  education: Array<ITeacherApplicationEducation>;
  workingExperience: Array<ITeacherApplicationWorkingExperience>;
  applicationStatusDetailStatus: StateStatus | null;
  applicationStatus: {
    status: StateStatus | null;
    data: { progress: number; message?: string | React.ReactElement };
  };
  school_options: {
    data: SchoolService.IFetchSchoolOptionsData | null;
    status: StateStatus | null;
  };
  submitStatuses: {
    data: Array<StatusOption> | [];
    status: StateStatus | null;
  };
  reviewApplicationStatus: StateStatus | null;
  review: ITeacherReview | null;
}

const initialState: IState = {
  teacherProfile: null,
  services: [],
  qualifications: [],
  education: [],
  workingExperience: [],
  applicationStatusDetailStatus: null,
  applicationStatus: {
    status: null,
    data: { progress: 0, message: undefined },
  },
  school_options: {
    data: null,
    status: null,
  },
  submitStatuses: {
    data: [],
    status: null,
  },
  reviewApplicationStatus: null,
  review: null,
};

const UserPorifleReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_TEACHER_SERVICES:
      return {
        ...state,
        services: action.data,
      };
    case Types.SET_TEACHER_QUALIFICATIONS:
      return {
        ...state,
        qualifications: action.data,
      };
    case Types.SET_TEACHER_EDUCATION:
      return {
        ...state,
        education: action.data,
      };
    case Types.SET_TEACHER_WORKING_EXPERIENCE:
      return {
        ...state,
        workingExperience: action.data,
      };
    case Types.UPDATE_TEACHER_APPLICATION:
      return {
        ...state,
        applicationStatus: {
          status: action.status as StateStatus,
          data: {
            progress: (action.data.progress || 0) * 100,
            message: action.data.message,
          },
        },
      };
    case Types.RESET_STORE:
      return {
        ...initialState,
      };
    case Types.SET_SCHOOL_LIST:
      return {
        ...state,
        school_options: {
          ...state.school_options,
          data:
            action.data === KEEP_THE_SAME
              ? state.school_options.data
              : action.data,
          status: action.status || null,
        },
      };
    case Types.GET_NEXT_STATUS_OPTIONS:
      return {
        ...state,
        submitStatuses: {
          data:
            action.data === KEEP_THE_SAME
              ? state.submitStatuses.data
              : action.data,
          status: action.status || null,
        },
      };
    case Types.SET_TEACHER_APPLICATION_BY_ID:
      return {
        ...state,
        applicationStatusDetailStatus: action.status as StateStatus,
        teacherProfile: action.data.profile,
        services: action.data.services ?? [],
        qualifications: action.data.qualifications ?? [],
        education: action.data.education ?? [],
        workingExperience: action.data.working_experience ?? [],
      };
    case Types.REVIEW_APPLICATION:
      return {
        ...state,
        reviewApplicationStatus: action.status || null,
      };
    case Types.FETCH_LATEST_REVIEW:
      return {
        ...state,
        review: action.data,
        reviewApplicationStatus: action.status || null,
      };
    default:
      return {
        ...state,
      };
  }
};

export default UserPorifleReducer;
