import {
  Box,
  Collapse,
  Fade,
  Icon,
  Typography,
  useTheme,
  Button,
} from '@material-ui/core';
import TranslatedText from 'components/atoms/translated_text';
import { AppTextField, FormCommon } from 'components/molecules/form_controls';
import _ from 'lodash';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelectUserData } from 'redux/reducers/gl_auth_reducer/selectors';
import { StateStatus } from 'redux/utils/common';
import { sendHelpMeEmail } from 'services/api/help_me';
import Translations from './translations';
import { useWindowDimensions } from 'hooks/windowDimension';
import { useHelpMePopupOpen } from './store/selectors';
import { ToggleAction } from './store/actions';
import { useDispatch } from 'react-redux';
import DoneIcon from '@material-ui/icons/CheckCircle';
import HelpIcon from '@material-ui/icons/Help';
import { useLocation } from 'react-router';

interface IFormData {
  subject: string;
  content: string;
}

function getDefaultValues(): IFormData {
  return {
    subject: '',
    content: '',
  };
}

export const MobileHelpMePopupWidth = 500;
export function HelpMePopup() {
  const location = useLocation();
  const currentPath = location.pathname;

  const theme = useTheme();
  const dispatch = useDispatch();

  const { width, height } = useWindowDimensions();

  const open = useHelpMePopupOpen();
  const user = useSelectUserData();

  const [mailStatus, setMailStatus] = useState<StateStatus | null>(null);

  const { control, reset, errors, handleSubmit } = useForm<IFormData>({
    defaultValues: getDefaultValues(),
  });

  return currentPath.startsWith('/payments') ? (
    <></>
  ) : (
    <Fade
      in={open && user != undefined && user.email != undefined}
      timeout={500}
    >
      <Box
        style={{
          position: 'fixed',
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          bottom: 0,
          zIndex: 1000,
          cursor: 'pointer',
          ...(width < MobileHelpMePopupWidth
            ? {
                width: '100%',
                left: '0px',
                right: '0px',
              }
            : {
                // width: open ? '250px' : '150px',
                width: '250px',
                right: '50px',
              }),
          // transition: 'all 500ms ease-in-out',
        }}
      >
        <Box
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          {/* expandable header */}
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              backgroundColor: theme.palette.primary.light,
              paddingTop: 2,
              paddingBottom: 2,
              paddingLeft: 5,
              paddingRight: 5,
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
              outline: '1px solid grey',
            }}
            onClick={() => dispatch(new ToggleAction().action())}
          >
            <Typography
              style={{
                color: theme.palette.getContrastText(
                  theme.palette.primary.light
                ),
              }}
            >
              Help Me
            </Typography>
            <Icon
              style={{
                marginLeft: '0.5em',
                // color: theme.palette.getContrastText(
                //   theme.palette.primary.light
                // ),
                color: '#7FB3D5',
              }}
            >
              <HelpIcon style={{ fontSize: 24 }} />
            </Icon>
          </Box>

          {/* content */}
          <Collapse in={open} timeout={500}>
            <Box
              style={{
                paddingLeft: 5,
                paddingRight: 5,
                paddingTop: 5,
                paddingBottom: 10,
                backgroundColor: theme.palette.background.default,
                outline: '1px solid grey',
                display: 'flex',
                flexDirection: 'column',
                // width: open ? '100%' : 0,
                // transition: 'all 500ms ease-in-out',
              }}
            >
              <AppTextField
                variant={'outlined'}
                label={'Subject'}
                style={{ marginTop: 10 }}
                size={'small'}
                control={control}
                rules={{
                  required: <FormCommon.RequiredField />,
                }}
                helperText={_.get(errors, 'subject.message', undefined)}
                error={'subject' in errors}
                name={'subject'}
                disabled={mailStatus == StateStatus.Pending}
              />
              <AppTextField
                variant={'outlined'}
                label={'Content'}
                multiline
                rows={5}
                style={{ marginTop: 10 }}
                control={control}
                rules={{
                  required: <FormCommon.RequiredField />,
                }}
                helperText={_.get(errors, 'content.message', undefined)}
                error={'content' in errors}
                name={'content'}
                disabled={mailStatus == StateStatus.Pending}
              />
              <Box style={{ display: 'flex' }}>
                <Fade in={mailStatus == StateStatus.Success} timeout={500}>
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon style={{ marginRight: '0.2em' }}>
                      <DoneIcon
                        style={{ color: theme.palette.success.light }}
                      />
                    </Icon>
                    <Typography>
                      <TranslatedText textMap={Translations.mail_success} />
                    </Typography>
                  </Box>
                </Fade>
                <Box style={{ display: 'flex', flex: 1, minWidth: '1em' }} />
                <Button
                  variant={'contained'}
                  color={'primary'}
                  style={{ marginTop: 10 }}
                  disabled={mailStatus == StateStatus.Pending}
                  onClick={() => {
                    setMailStatus(StateStatus.Pending);
                    handleSubmit(
                      async (data) => {
                        await sendHelpMeEmail(data);
                        reset(getDefaultValues());
                        setMailStatus(StateStatus.Success);
                      },
                      (errors) => {
                        setMailStatus(StateStatus.Failed);
                      }
                    )();
                  }}
                >
                  Send
                </Button>
              </Box>
            </Box>
          </Collapse>
        </Box>
      </Box>
    </Fade>
  );
}
