// eslint-disable-next-line
import React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import { Box, LinearProgress } from '@material-ui/core';
import useStyles from './styles';
import clsx from 'clsx';
import { clamp } from 'lodash';

interface ILoadingViewProps extends CircularProgressProps {
  fullScreen?: boolean;
  containerProps?: any;
  progress?: number;
}

export default function CircularLoading(props: ILoadingViewProps) {
  const { fullScreen, className, containerProps, progress, ...rest } = props;
  const classes = useStyles({});
  let percentage;
  if (progress) {
    percentage = clamp(progress, 0, 100);
  }
  return (
    <Box
      className={clsx({
        [classes.fullScreen]: fullScreen,
      })}
      {...containerProps}
    >
      <CircularProgress
        className={clsx(classes.loadingView, className)}
        {...rest}
      />
    </Box>
  );
}
