import { useEffect, useRef } from 'react';

export default function useTimeout(callback: () => void, delay: number) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay && callback && typeof callback === 'function') {
      let timer = setTimeout(callbackRef.current, delay || 0);
      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    }
  }, [callback, delay]);
}
