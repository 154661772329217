// eslint-disable-next-line
import React from 'react';
import useTimeout from 'custom_hooks/useTimeout';
import { Backdrop, Fade, Typography, Zoom } from '@material-ui/core';
import useStyles from './styles';
import CircularLoading from 'components/atoms/loading_view/CircularLoading';

interface CustomSuspenseProps extends Omit<React.SuspenseProps, 'fallback'> {
  fallback?: keyof React.SuspenseProps['fallback'];
}

interface IAppLoadingProps {
  delay?: number;
}

function AppLoading(props: IAppLoadingProps) {
  const classes = useStyles({});
  const [showLoading, setShowLoading] = React.useState(!props.delay);

  useTimeout(() => {
    setShowLoading(true);
  }, props.delay || 0);

  if (!showLoading) {
    return null;
  }

  return (
    <Backdrop in={true} open={true} style={{ zIndex: 3000 }} timeout={2000}>
      <Zoom in={true} timeout={200}>
        <CircularLoading />
      </Zoom>
    </Backdrop>
  );
}

const CustomSuspense = (props: CustomSuspenseProps) => {
  return <React.Suspense fallback={<AppLoading />} {...props} />;
};

export default React.memo(CustomSuspense);
