import React from 'react';
import * as Config from '../../config';
import MainPage from '../../scenes/main_page';
import RootPage from '../../scenes/root_page';
import AppbarMenu from '../components/appbar';
import ApplicationDetailModes from '../../config/applicationDetailMode';

// const ChartsPage = React.lazy(() => import('samples/charts_page'));
const FormsPage = React.lazy(() => import('samples/forms_page'));
// const MapsPage = React.lazy(() => import('samples/maps_page'));
// const DatatablesPage = React.lazy(() => import('samples/datatables_page'));
// const DashboardV1Page = React.lazy(() => import('samples/dashboardV1_page'));
// const DashboardV2Page = React.lazy(() => import('samples/dashboardV2_page'));
// const DashboardBuilderPage = React.lazy(
//   () => import('samples/dashboardBuilder_page')
// );
const ApplicationTeacherPage = React.lazy(
  () => import('scenes/application_teacher_page')
);
const ApplicationSchoolPage = React.lazy(
  () => import('scenes/application_school_page')
);
const IndictmentPage = React.lazy(() => import('scenes/indictment_page'));
const ProfilePage = React.lazy(() => import('scenes/profile_page'));
const SecurityPage = React.lazy(() => import('scenes/security_page'));
const ResetPasswordPage = React.lazy(() => import('scenes/reset_password'));
const ForgotPasswordPage = React.lazy(() => import('scenes/forgot_password'));
const RegisterPage = React.lazy(() => import('scenes/register_page'));
const LoginPage = React.lazy(() => import('scenes/login_page'));
const ApplicationListPage = React.lazy(() => import('scenes/application_list'));
const ApplicationSchoolListPage = React.lazy(
  () => import('scenes/application_school_list_page')
);
const ApplicationTeacherListPage = React.lazy(
  () => import('scenes/application_teacher_list_page')
);
const IndictmentListPage = React.lazy(
  () => import('scenes/indictment_list_page')
);
const TeachersListPage = React.lazy(() => import('scenes/teachers_list_page'));
const SchoolPage = React.lazy(() => import('scenes/school_list_page'));
const SchoolDetailsPage = React.lazy(
  () => import('scenes/school_details_page')
);
const TeacherDetailsPage = React.lazy(
  () => import('scenes/teacher_details_page')
);
const UserCalendarPage = React.lazy(() => import('scenes/user_calendar_page'));
const UserManagementPage = React.lazy(
  () => import('scenes/user_management_page')
);
const VisitsListPage = React.lazy(() => import('scenes/visits_list_page'));
const PaymentLandingPage = React.lazy(
  () => import('scenes/payment_landing_page')
);
const ReportingListPage = React.lazy(
  () => import('scenes/reporting_list_page')
);
// const HistoryPage = React.lazy(
//   () => import('components/app_common/review_history')
// );

export interface IPage {
  path: string;
  component: any;
  roles: Array<number | string>;
}

interface INavigationConfig {
  rootPage: () => IPage;
  mainPage: () => IPage;
  formsPage: () => IPage;
  loginPage: () => IPage;
  registerPage: () => IPage;
  forgotPasswordPage: () => IPage;
  resetPasswordPage: (token?: string) => IPage;
  securityPage: () => IPage;
  profilePage: () => IPage;
  applicationTeacherPage: (params?: {
    id?: string | number;
    mode?: ApplicationDetailModes;
  }) => IPage;
  addTeacherApplicationPage: (params?: {
    id?: string | number | 'new';
  }) => IPage;
  applicationSchoolPage: (params?: {
    id?: string | number;
    mode?: ApplicationDetailModes;
  }) => IPage;
  addSchoolApplicationPage: (params?: {
    id?: string | number | 'new';
  }) => IPage;
  indictmentPage: (params?: {
    id?: string | number;
    mode?: ApplicationDetailModes;
  }) => IPage;
  applicationListPage: () => IPage;
  aplicationSchoolListPage: () => IPage;
  aplicationTeacherListPage: () => IPage;
  indictmentListPage: () => IPage;
  teachersListPage: () => IPage;
  schoolListPage: () => IPage;
  schoolDetailsPage: (params?: { id?: string | number }) => IPage;
  teacherDetailsPage: (params?: { id?: string | number }) => IPage;
  userCalendarPage: () => IPage;
  userManagementPage: () => IPage;
  visitsListPage: () => IPage;
  paymentLangingPage: () => IPage;
  reportingListPage: () => IPage;
  // HistoryPage: () => IPage;
}

interface INav {
  component: any;
  include: 'all' | string[] | null;
  exclude: 'all' | string[] | null;
  select: 'include' | 'exclude';
}

interface IAppbarsConfig {
  primaryNav: INav;
  secondaryNav: INav;
}

const NavigationConfig: INavigationConfig = {
  rootPage: (params?: any): IPage => ({
    path: '*',
    component: RootPage,
    roles: Config.AuthRoles.All,
  }),
  mainPage: (params?: any) => ({
    path: `/`,
    component: MainPage,
    roles: Config.AuthRoles.All,
  }),
  aplicationSchoolListPage: (params?: any) => ({
    path: `/application/school-list`,
    component: ApplicationSchoolListPage,
    roles: Config.AuthRoles.PersonelSecure,
  }),
  aplicationTeacherListPage: (params?: any) => ({
    path: `/application/teacher-list`,
    component: ApplicationTeacherListPage,
    roles: Config.AuthRoles.PersonelSecure,
  }),
  indictmentListPage: (params?: any) => ({
    path: `/indictment-list`,
    component: IndictmentListPage,
    roles: [Config.AuthRoles.Role12, ...Config.AuthRoles.PersonelSecure],
  }),
  teachersListPage: (params?: any) => ({
    path: `/teachers`,
    component: TeachersListPage,
    roles: Config.AuthRoles.PersonelSecure,
  }),
  applicationListPage: (params?: any) => ({
    path: `/application/list`,
    component: ApplicationListPage,
    roles: Config.AuthRoles.Registered,
  }),
  formsPage: (params?: any) => ({
    path: `/forms`,
    component: FormsPage,
    roles: Config.AuthRoles.Registered,
  }),
  addTeacherApplicationPage: ({ id = ':id' } = {}) => ({
    path: `/application/teacher/${id}`,
    component: ApplicationTeacherPage,
    roles: Config.AuthRoles.Registered,
  }),
  applicationTeacherPage: ({ id = ':id', mode = ':mode' } = {}) => ({
    path: `/application/teacher/${mode}/${id}`,
    component: ApplicationTeacherPage,
    roles: Config.AuthRoles.Registered,
  }),
  applicationSchoolPage: ({ id = ':id', mode = ':mode' } = {}) => ({
    path: `/application/school/${mode}/${id}`,
    component: ApplicationSchoolPage,
    roles: Config.AuthRoles.Registered,
  }),
  indictmentPage: ({ id = ':id', mode = ':mode' } = {}) => ({
    path: `/indictment/${mode}/${id}`,
    component: IndictmentPage,
    roles: [Config.AuthRoles.Role12, ...Config.AuthRoles.PersonelSecure],
  }),
  addSchoolApplicationPage: ({ id = ':id' } = {}) => ({
    path: `/application/school/${id}`,
    component: ApplicationSchoolPage,
    roles: Config.AuthRoles.Registered,
  }),
  schoolListPage: () => ({
    path: `/schools`,
    component: SchoolPage,
    roles: Config.AuthRoles.PersonelSecure,
  }),
  userCalendarPage: () => ({
    path: `/calendar`,
    component: UserCalendarPage,
    roles: Config.AuthRoles.PersonelSecure,
  }),
  userManagementPage: () => ({
    path: `/users`,
    component: UserManagementPage,
    roles: [Config.AuthRoles.Admin],
  }),
  // HistoryPage: () => ({
  //   path: `/history`,
  //   component: HistoryPage,
  //   roles: Config.AuthRoles.Personel
  // }),
  // mapsPage: () => ({
  //   path: `/maps`,
  //   component: MapsPage,
  //   roles: Config.AuthRoles.Registered,
  // }),
  // dashboardV2Page: (params?: any): IPage => ({
  //   path: '/dashboard/v2',
  //   component: DashboardV2Page,
  //   roles: Config.AuthRoles.Registered,
  // }),
  loginPage: () => ({
    path: '/login',
    component: LoginPage,
    roles: [Config.AuthRoles.Guest],
  }),
  // loginNoAuthPage: () => ({
  //   path: '/no-auth/login',
  //   component: LoginPage,
  //   roles: Config.AuthRoles.All,
  // }),
  registerPage: () => ({
    path: '/register',
    component: RegisterPage,
    roles: [Config.AuthRoles.Guest],
  }),
  // registerNoAuthPage: () => ({
  //   path: '/no-auth/register',
  //   component: RegisterPage,
  //   roles: Config.AuthRoles.All,
  // }),
  forgotPasswordPage: () => ({
    path: '/forgot',
    component: ForgotPasswordPage,
    roles: Config.AuthRoles.All,
  }),
  // forgotPasswordNoAuthPage: () => ({
  //   path: '/no-auth/forgot',
  //   component: ForgotPasswordPage,
  //   roles: Config.AuthRoles.All,
  // }),
  resetPasswordPage: (token = ':token') => ({
    path: `/reset-password/${token}`,
    component: ResetPasswordPage,
    roles: Config.AuthRoles.All,
  }),
  // dashboardBuilderPage: () => ({
  //   path: '/dashboard-builder',
  //   component: DashboardBuilderPage,
  //   roles: Config.AuthRoles.Registered,
  // }),
  // datatablesPage: () => ({
  //   path: `/datatables`,
  //   component: DatatablesPage,
  //   roles: Config.AuthRoles.Registered,
  // }),
  securityPage: () => ({
    path: `/security`,
    component: SecurityPage,
    roles: Config.AuthRoles.Registered,
  }),
  profilePage: () => ({
    path: `/profile`,
    component: ProfilePage,
    roles: Config.AuthRoles.Registered,
  }),
  schoolDetailsPage: ({ id = ':id' } = {}) => ({
    path: `/school/details/${id}`,
    component: SchoolDetailsPage,
    roles: [Config.AuthRoles.Admin],
  }),
  teacherDetailsPage: ({ id = ':id' } = {}) => ({
    path: `/teacher/details/${id}`,
    component: TeacherDetailsPage,
    roles: [Config.AuthRoles.Admin],
  }),
  visitsListPage: () => ({
    path: '/visits',
    component: VisitsListPage,
    roles: Config.AuthRoles.PersonelSecure,
  }),
  paymentLangingPage: () => ({
    path: '/payments/result',
    component: PaymentLandingPage,
    roles: Config.AuthRoles.Registered,
  }),
  reportingListPage: () => ({
    path: '/reports',
    component: ReportingListPage,
    roles: [Config.AuthRoles.Admin],
  }),
  // chartsPage: () => ({
  //   path: `/charts`,
  //   component: ChartsPage,
  //   roles: Config.AuthRoles.Registered,
  // }),
  // dashboardV1Page: (params?: any): IPage => ({
  //   path: '/dashboard/v1',
  //   component: DashboardV1Page,
  //   roles: Config.AuthRoles.Registered,
  // }),
  // mobileEntrypointPage: (params?: any): IPage => ({
  //   path: '/mobile/entrypoint',
  //   component: EntrypointPage,
  //   roles: Config.AuthRoles.All,
  // }),
};

export const AppbarsConfig: IAppbarsConfig = {
  primaryNav: {
    component: AppbarMenu,
    include: null,
    exclude: [
      NavigationConfig.loginPage().path,
      NavigationConfig.registerPage().path,
      NavigationConfig.forgotPasswordPage().path,
      NavigationConfig.resetPasswordPage().path,
      NavigationConfig.paymentLangingPage().path,
    ],
    select: 'exclude',
  },
  secondaryNav: {
    component: AppbarMenu,
    include: [],
    exclude: 'all',
    select: 'exclude',
  },
};

export const Appbars: Array<INav> = Object.values(AppbarsConfig);
export default NavigationConfig;
