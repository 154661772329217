// eslint-disable-next-line
import React, { ReactElement } from 'react';
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  CheckboxProps,
  FormHelperText,
} from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';
import useStyles from './styles';

interface IIcon {
  position: 'left' | 'right';
  Component: ReactElement;
}

// interface IValue {
//   label: any;
//   value: any;
// }

interface Iprops extends CheckboxProps {
  control: Control;
  rules: any;
  icon?: IIcon;
  label: string | React.ReactElement;
  name: string;
  // value: IValue;
  helperText?: string | React.ReactElement;
  error: boolean;
  disabled?: boolean;
  color?: 'primary' | 'secondary';
  controllerExtras?: any;
  margin?: 'none' | 'normal';
  labelPlacement?: 'bottom' | 'top' | 'end' | 'start';
}

export default function AppCheckGroup(props: Iprops) {
  const {
    control,
    error,
    helperText,
    label,
    icon,
    rules,
    // values,
    disabled,
    margin,
    color,
    controllerExtras = {},
    labelPlacement,
    ...other
  } = props;
  const classes = useStyles({ margin });

  return (
    <Controller
      render={({ value, onChange, ...rest }) => (
        <FormControl
          // component="fieldset"
          error={error}
          disabled={Boolean(disabled)}
          className={classes.formControl}
          style={{ color: error ? 'red' : undefined }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
                {...rest}
                {...other}
              />
            }
            label={label}
            labelPlacement={labelPlacement}
          />
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      )}
      name={other.name}
      control={control}
      rules={rules}
      {...controllerExtras}
    />
  );
}
