import React from 'react';
import { Button, Typography } from '@material-ui/core';

interface IUploadButtonProps {
  label: string | React.ReactElement;
  variant?: 'outlined' | 'contained';
  size?: 'small' | 'medium';
  disabled?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  icon: React.ReactNode;
  handleClick?: (event: any) => void;
}
export function UploadButton({
  label,
  variant,
  size,
  disabled,
  fullWidth,
  error,
  icon,
  handleClick,
}: IUploadButtonProps) {
  const ERROR_COLOR = '#f44336';
  return (
    <Button
      style={{
        padding: size === 'medium' ? '0.8rem' : '0.4rem',
        textTransform: 'capitalize',
        margin: 'auto',
        color: error ? ERROR_COLOR : undefined,
        borderColor: error ? ERROR_COLOR : undefined,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      disabled={disabled}
      variant={variant || 'outlined'}
      size="large"
      fullWidth={fullWidth || true}
      onClick={handleClick}
    >
      {icon}
      <Typography component="h5">{label}</Typography>
    </Button>
  );
}
