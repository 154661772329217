import Translation from 'config/languages';

export default {
  sidebarApplications: {
    [Translation.English]: 'Applications',
    [Translation.Greek]: 'Αιτήσεις',
  },
  sidebarAdministration: {
    [Translation.English]: 'Administration',
    [Translation.Greek]: 'Διαχείριση',
  },
  sidebarApplicationsSchools: {
    [Translation.English]: 'School Applications',
    [Translation.Greek]: 'Αιτήσεις Σχολών',
  },
  sidebarApplicationsTeachers: {
    [Translation.English]: 'Trainer Applications',
    [Translation.Greek]: 'Αιτήσεις Εκπαιδευτών',
  },
  sidebarSchools: {
    [Translation.English]: 'School',
    [Translation.Greek]: 'Σχολές',
  },
  sidebarTeachers: {
    [Translation.English]: 'Trainers',
    [Translation.Greek]: 'Εκπαιδευτές',
  },
  sidebarIndictments: {
    [Translation.English]: 'Indictments',
    [Translation.Greek]: 'Κατηγορητήρια',
  },
  sidebarUserManagementPage: {
    [Translation.English]: 'User Management',
    [Translation.Greek]: 'Διαχείριση χρηστών',
  },
  sidebarReportingPage: {
    [Translation.English]: 'Reports',
    [Translation.Greek]: 'Reports',
  },
  sidebarUserCalendarPage: {
    [Translation.English]: 'Communications',
    [Translation.Greek]: 'Επικοινωνία',
  },
  sidebarVisitsListPage: {
    [Translation.English]: 'Visits',
    [Translation.Greek]: 'Επισκέψεις',
  },
};
