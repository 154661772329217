// eslint-disable-next-line
import React, { ReactElement } from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import useStyles from './styles';
import { MenuItem } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import FormattedMenu from 'components/atoms/formatted_menu';

interface IItem {
  action: () => void;
  id: string;
  children?: ReactElement;
}

interface IProps {
  items: IItem[];
  children: ReactElement;
  action?: (open: boolean) => void;
  buttonProps?: ButtonProps;
}

export default function SelectButton(props: IProps) {
  const classes = useStyles({});
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose =
    (action: null | (() => void)) => (event: React.MouseEvent<EventTarget>) => {
      if (
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
      ) {
        return;
      }
      action && action();
      handleMenuClose();
    };

  const _buttonProps: ButtonProps = props.buttonProps || {};
  const ArrowIcon = React.useCallback(
    () => (
      <ExpandMoreIcon
        className={clsx(classes.expand, {
          [classes.expandOpen]: open,
        })}
        aria-expanded={open}
      />
    ),
    [open, classes.expand, classes.expandOpen]
  );

  return (
    <React.Fragment>
      {props.items.length === 0 ? (
        <Button
          ref={anchorRef}
          onClick={() => props.action && props.action(open)}
          {..._buttonProps}
        >
          {props.children}
        </Button>
      ) : (
        <React.Fragment>
          <Button
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            {..._buttonProps}
          >
            <React.Fragment>
              {props.children}
              <ArrowIcon />
            </React.Fragment>
          </Button>
          <FormattedMenu
            anchorEl={anchorEl}
            id="select-button-menu"
            open={open}
            onClose={handleMenuClose}
            keepMounted={false}
          >
            {props.items.map(({ children, action, id }, index) => (
              <MenuItem key={id} onClick={handleClose(() => action())}>
                {children}
              </MenuItem>
            ))}
          </FormattedMenu>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
