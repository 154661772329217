import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as ApplicationSchoolService from 'services/api/application_school';

export interface IState {
  applicationSchoolList: {
    data: ApplicationSchoolService.IFetchApplicationSchoolListData | null;
    status: StateStatus | null;
  };
}

const initialState: IState = {
  applicationSchoolList: {
    data: null,
    status: null,
  },
};

const ApplicationSchoolListReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    // SET_SCHOOL_APPLICATIONS
    case Types.SET_SCHOOL_APPLICATIONS:
      return {
        ...state,
        applicationSchoolList: {
          ...state.applicationSchoolList,
          data:
            action.data === KEEP_THE_SAME
              ? state.applicationSchoolList.data
              : action.data,
          status: action.status || null,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default ApplicationSchoolListReducer;
