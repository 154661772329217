// eslint-disable-next-line
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import useStyles from './styles';
import { useModalData } from '../../../redux/reducers/gl_modal_reducer/selectors';
import { ModalIDs } from '../../../redux/reducers/gl_modal_reducer/types';
import { useDispatch } from 'react-redux';
import ModalActions from '../../../redux/reducers/gl_modal_reducer/actions';
import TranslatedText from '../translated_text';
import { Dispatch } from 'redux';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MODAL_ID = ModalIDs.TOAST;

export default function ToastMessage() {
  const classes = useStyles({});
  const { params, visible } = useModalData(MODAL_ID);
  const open = visible;
  const dispatch = useDispatch();
  const { message, type } = params;

  // const handleClick = () => {
  //   // setOpen(true);
  //   dispatch(ModalActions.openModalAction());
  // };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(
      ModalActions.closeModalAction({
        modalID: MODAL_ID,
      })
    );
  };

  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={type || 'success'}>
          <TranslatedText textMap={message} defaultText={message} />
        </Alert>
      </Snackbar>
    </div>
  );
}

export const showToastAction = async (
  dispatch: Dispatch<any>,
  message: any,
  type: 'error' | 'warning' | 'info' | 'success'
) => {
  dispatch(
    ModalActions.openModalAction({
      modalID: MODAL_ID,
      params: {
        message,
        type,
      },
    })
  );
};

export const hideToastAction = (dispatch: Dispatch<any>) => {
  dispatch(
    ModalActions.closeModalAction({
      modalID: MODAL_ID,
    })
  );
};
