import { typeCreatorV2 } from '../../../redux/utils/common';

//  [REDUCER][TYPE]
export const SEARCH_VISITS: string = typeCreatorV2(
  'VisitsReducer',
  'SearchVisits'
);

export const INSERT_VISIT: string = typeCreatorV2(
  'VisitsReducer',
  'InsertVisit'
);
