// eslint-disable-next-line
import React, { useCallback } from 'react';
import {
  TextField,
  StandardTextFieldProps,
  CircularProgress,
} from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';
import Autocomplete, {
  AutocompleteGetTagProps,
} from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import { IOption } from '../select';

interface Iprops extends StandardTextFieldProps {
  options: Array<IOption>;
  control: Control;
  rules: any;
  selectedKey?: string;
  name: string;
  handleChange?: (data: any) => void;
  controllerExtras?: any;
  style?: React.CSSProperties;
  multiple?: boolean;
  helperText?: React.ReactNode;
  error?: boolean;
  getOptionLabel?: (option: any) => string;
  renderTags?: (
    value: any[],
    getTagProps: AutocompleteGetTagProps
  ) => React.ReactNode;
}

export default function MultipleAutoCompleteSelect(props: Iprops) {
  const {
    control,
    rules,
    options,
    handleChange,
    selectedKey = 'label',
    style,
    id,
    name,
    controllerExtras = {},
    multiple,
    helperText,
    error = false,
    getOptionLabel,
    renderTags,
    fullWidth = true,
    ...other
  } = props;

  const getValue = useCallback(
    (value) => {
      return value && !value?.id && options && options.length > 0
        ? options.find((el) => el.id == value) || value
        : value;
    },
    [options]
  );

  return (
    <Controller
      render={(props) => {
        // console.log(props.value);
        if (!props.value?.id) {
          props.value = getValue(props.value);
        }

        return (
          <Autocomplete
            {...props}
            loading={!options || !options?.length}
            loadingText={<CircularProgress />}
            autoComplete={false}
            multiple={multiple}
            options={options}
            id={id}
            filterSelectedOptions
            disableCloseOnSelect
            disabled={other.disabled}
            getOptionSelected={(option, value) => {
              // console.log(option.id == _.get(value, 'id', value));
              return option.id == _.get(value, 'id', value);
            }}
            getOptionLabel={
              getOptionLabel
                ? getOptionLabel
                : (option) => {
                    // console.log(_.get(option, selectedKey, option || '') || '');
                    return _.get(option, selectedKey, option || '') || '';
                  }
            }
            renderOption={(option, state) => {
              // console.log(_.get(option, selectedKey, option || '') || '');
              return _.get(option, selectedKey, option || '') || '';
            }}
            onChange={(__, result) => {
              handleChange && handleChange(result);
              props.onChange(result);
            }}
            renderTags={renderTags}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  helperText={helperText}
                  error={error}
                  variant="outlined"
                  {...other}
                  fullWidth={fullWidth}
                />
              );
            }}
            style={style}
            // defaultValue={}
          />
        );
      }}
      name={name}
      control={control}
      rules={rules}
      {...controllerExtras}
    />
  );
}
