export enum LanguagesEnum {
  English = 'English',
  Greek = 'Greek',
}

export enum LanguagesEnumIndex {
  English = 1,
  Greek = 2,
}

export default {
  ...LanguagesEnum,
  All: Object.values(LanguagesEnum),
};
