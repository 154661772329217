import {
  IDashboard,
  EventsEnum,
} from 'components/compounds/dashboard_builder/plugins/types';
import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';

interface IState {
  dataFields: {
    status: StateStatus | null | undefined;
    data: any;
  };
  builderConfiguration: {
    status: StateStatus | null | undefined;
    data: IDashboard;
  };
  selectedElement: {
    status: StateStatus | null | undefined;
    data: any;
  };
  palleteIsOpen: boolean;
  dashboardsConfig: {
    status: StateStatus | null | undefined;
    data: any;
  };
  saveDashboard: {
    status: StateStatus | null | undefined;
  };
  selectedDashboardid: string | null;
}

const initialState: IState = {
  dataFields: {
    status: null,
    data: null,
  },
  builderConfiguration: {
    status: null,
    data: {
      dashboardElements: [],
      title: '',
      globals: {
        [EventsEnum.MAP_ONCLICK_KEY]: 'id',
        [EventsEnum.MAP_ONCLICK_VALUE]: '-',
        [EventsEnum.MAP_ZOOM_KEY]: 'id',
        [EventsEnum.MAP_ZOOM_VALUE]: '-',
      },
    },
  },
  selectedElement: {
    status: null,
    data: null,
  },
  palleteIsOpen: true,
  dashboardsConfig: {
    status: null,
    data: null,
  },
  saveDashboard: {
    status: null,
  },
  selectedDashboardid: null,
};

const DashboardBuilderReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_DATASETS_FIELDS:
      return {
        ...state,
        dataFields: {
          ...state.dataFields,
          status: action.status,
          data:
            action.data === KEEP_THE_SAME ? state.dataFields.data : action.data,
        },
      };
    case Types.SET_BUILDER_CONFIGURATION:
      return {
        ...state,
        selectedElement: {
          status: null,
          data: null,
        },
        dataFields: {
          status: null,
          data: null,
        },
        builderConfiguration: {
          ...state.builderConfiguration,
          status: action.status,
          data:
            action.data === KEEP_THE_SAME
              ? state.builderConfiguration.data
              : action.data,
        },
      };
    case Types.SET_ELEMENT_SELECTED:
      return {
        ...state,
        selectedElement: {
          ...state.selectedElement,
          status: action.status,
          data: action.data,
        },
      };
    case Types.SET_PALLETE_VISIBILITY:
      return {
        ...state,
        palleteIsOpen: action.data,
      };
    case Types.SET_DASHBOARDS:
      return {
        ...state,
        dashboardsConfig: {
          ...state.dashboardsConfig,
          status: action.status,
          data: action.data,
        },
      };
    case Types.SET_SELECTED_DASHBOARD:
      return {
        ...state,
        selectedDashboardid:
          action.data === KEEP_THE_SAME
            ? state.selectedDashboardid
            : action.data.id,
        builderConfiguration: {
          ...state.builderConfiguration,
          status: action.status,
          data:
            action.data === KEEP_THE_SAME
              ? state.builderConfiguration.data
              : action.data.dashboard,
        },
      };
    case Types.SAVE_DASHBOARD:
      return {
        ...state,
        saveDashboard: {
          ...state.saveDashboard,
          status: action.status,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default DashboardBuilderReducer;
