export enum ReducerKeys {
  AUTH_REDUCER = 'AuthReducer',
  TRANSL_REDUCER = 'TranslationReducer',
  NAVIGATION_REDUCER = 'NavigationReducer',
  MODAL_REDUCER = 'ModalReducer',
  THEME_REDUCER = 'ThemeReducer',
  COMMON_REDUCER = 'CommonReducer',
  RESET_PASSOWRD_REDUCER = 'ResetPasswordReducer',
  REGISTER_REDUCER = 'RegisterReducer',
  CHANGE_PASSWORD_REDUCER = 'ChangePasswordReducer',
  USER_PROFILE_REDUCER = 'UserProfileReducer',
  DASHBOARD_BUILDER_REDUCER = 'DashboardBuilderReducer',
  FORGOT_PASSWORD_REDUCER = 'ForgotPasswordReducer',
  TEACHER_APPLICATION_REDUCER = 'TeacherApplicationReducer',
  APPLICATION_SCHOOL_REDUCER = 'ApplicationSchoolReducer',
  USER_APPLICATION_LIST_REDUCER = 'UserApplicationListReducer',
  APPLICATION_SCHOOL_LIST_REDUCER = 'ApplicationSchoolListReducer',
  APPLICATION_TEACHER_LIST_REDUCER = 'ApplicationTeacherListReducer',
  TEACHER_LIST_REDUCER = 'TeacherListReducer',
  SCHOOLS_REDUCER = 'SchoolsReducer',
  SCHOOL_DETAILS_REDUCER = 'SchoolDetailsReducer',
  TEACHER_DETAILS_REDUCER = 'TeacherDetailsReducer',
  INDICTMENT_LIST_REDUCER = 'IndictmentListReducer',
  INDICTMENT_REDUCER = 'IndictmentReducer',
  REPORT_LIST_REDUCER = 'ReportListReducer',
  COMMUNICATIONS_REDUCER = 'CommunicationsReducer',
  REVIEW_HISTORY_REDUCER = 'ReviewHistoryReducer',
  USERS_REDUCER = 'UsersReducer',
  VISITS_REDUCER = 'VisitsReducer',
  HELP_ME_POPUP_REDUCER = 'HelpMePopupReducer',
}
