//  [REDUCER][TYPE]
import { typeCreatorV2 } from '../../utils/common';

export const LOGOUT_TYPE: string = typeCreatorV2('AuthReducer', 'Logout');
export const LOGIN_TYPE: string = typeCreatorV2('AuthReducer', 'Login');
export const USER_DETAILS_TYPE: string = typeCreatorV2(
  'AuthReducer',
  'UserDetailsType'
);

export const REFRESH_TOKEN_TYPE: string = typeCreatorV2(
  'AuthReducer',
  'RefreshTokenType'
);

export const TOKEN_LOGIN_TYPE: string = typeCreatorV2(
  'AuthReducer',
  'TokenLoginType'
);

// export const LOGIN_GUEST: string = typeCreatorV2('AuthReducer', 'LoginGuest');
