import { IAction, IActionMethods, StateStatus } from '../../utils/common';
import * as Types from './types';
import * as AuthenticationService from '../../../services/api/authenticate';
import { Dispatch } from 'redux';
import NavigationConfig from '../../../navigation/config';
import _ from 'lodash';
import { showToastAction } from 'components/atoms/toast_message';
import store from 'redux/store';

/** Authenticate Action  */

interface IAutheticateViaPasswordInput {
  email: string;
  password: string;
  stayLogin: boolean;
}

interface IAutheticateViaTokenInput {
  token: string;
  refreshToken: string;
  stayLogin: boolean;
}

interface IAutheticateOutput {
  token: string;
  refreshToken: string;
  roles: Array<string | number>;
  id: string;
  stayLogin: boolean;
  email: string;
  username: string;
}

class Authenticate implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.LOGIN_TYPE,
      status: StateStatus.Pending,
      data: {},
    };
  }
  onSuccess(result: IAutheticateOutput): IAction {
    const data = {
      roles: result.roles,
      token: result.token,
      refreshToken: result.refreshToken,
      stayLogin: result.stayLogin,
      data: {
        username: result.username,
        email: result.email,
        id: result.id,
      },
    };

    return {
      type: Types.LOGIN_TYPE,
      status: StateStatus.Success,
      data: data,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.LOGIN_TYPE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: IAutheticateViaPasswordInput | IAutheticateViaTokenInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        dispatch(this.onPending());

        let data: IAutheticateOutput | null = null;
        if ('password' in input) {
          let _input: IAutheticateViaPasswordInput = input as any;
          const result = await AuthenticationService.authenticateUser({
            email: _input.email,
            password: _input.password,
          });
          data = {
            token: result.data.access_token,
            roles: result.data.user.roles,
            id: result.data.user.uuid,
            refreshToken: result.data.access_token,
            email: _.get(result.data.user, 'data.email', null),
            username: _.get(result.data.user, 'data.username', null),
            stayLogin: _input.stayLogin,
          };
        } else {
          // login with email and password
          let _input: IAutheticateViaTokenInput = input as any;
          const result = await AuthenticationService.getUserData({
            token: _input.token,
            refreshToken: _input.refreshToken,
          });
          // data = result.data;
          data = {
            token: _input.token,
            roles: result.data.roles,
            id: result.data.id,
            refreshToken: _input.refreshToken,
            email: result.data.email,
            username: result.data.username,
            stayLogin: _input.stayLogin,
          };
        }

        dispatch(this.onSuccess(data));

        // {
        //   token: data.access_token,
        //   roles: data.user.roles,
        //   id: data.user.uuid,
        //   stayLogin: true,
        //   refreshToken: data.access_token,
        //   email: _.get(data.user, 'data.email', null),
        //   username: _.get(data.user, 'data.username', null),
        // }
      } catch (error: any) {
        console.log('Authenticate Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

/** Logout Action  */
class Logout implements IActionMethods {
  onPending(result?: any): IAction {
    throw new Error('Method not implemented.');
  }
  onSuccess(): IAction {
    return {
      type: Types.LOGOUT_TYPE,
      status: StateStatus.Success,
      data: {},
    };
  }

  onFailed(): IAction {
    return {
      type: Types.LOGOUT_TYPE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(history?: any): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        // for webview
        (window as any)?.ReactNativeWebView &&
          (window as any).ReactNativeWebView.postMessage('LOGOUT');
        dispatch(this.onSuccess());
        showToastAction(dispatch, 'Logout', 'info');
      } catch (error: any) {
        console.log('Logout Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      } finally {
        // localStorage.clear();
        if (history) {
          history.push(NavigationConfig.loginPage().path);
        }
      }
    };
  }
}

/** Refresh Token Action  */

interface IRefreshTokenInput {
  token: string;
  refreshToken: string;
}

interface IRefreshTokenOutput {
  token: string;
  refreshToken: string;
}

class RefreshToken implements IActionMethods {
  onPending(result?: any): IAction {
    throw new Error('Method not implemented.');
  }
  onSuccess(data: IRefreshTokenOutput): IAction {
    return {
      type: Types.REFRESH_TOKEN_TYPE,
      data,
    };
  }

  onFailed(): IAction {
    throw new Error('Method not implemented.');
  }

  action(data: IRefreshTokenInput): IAction {
    return this.onSuccess(data);
  }
}

interface IGetAuthDataInput {
  overrideAuth?: {
    token: string;
    refreshToken: string;
  };
}

class FetchUserData implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.USER_DETAILS_TYPE,
      status: StateStatus.Pending,
      data: {},
    };
  }

  onSuccess(data: {
    roles: Array<string | number>;
    id: string;
    email: string;
    username: string;
    token?: string;
    refreshToken?: string;
  }): IAction {
    const { roles, id, username, email, token, refreshToken } = data;
    return {
      type: Types.USER_DETAILS_TYPE,
      status: StateStatus.Success,
      data: {
        token: token || store.getState().AuthReducer.token,
        refreshToken: refreshToken || store.getState().AuthReducer.refreshToken,
        roles,
        data: {
          username,
          email,
          id,
        },
      },
    };
  }

  onFailed(): IAction {
    return {
      type: Types.USER_DETAILS_TYPE,
      status: StateStatus.Failed,
      data: {},
    };
  }

  action(input: IGetAuthDataInput): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        // alert('ALERT???????' + JSON.stringify(input));

        // console.log('ookiesToken && ', input.overrideAuth);
        dispatch(this.onPending());
        const { data } = await AuthenticationService.getUserData({
          token: input.overrideAuth?.token,
          refreshToken: input.overrideAuth?.refreshToken,
        });
        dispatch(
          this.onSuccess({
            roles: data.roles,
            id: data.id,
            username: data.username,
            email: data.email,
            token: input.overrideAuth?.token,
            refreshToken: input.overrideAuth?.refreshToken,
          })
        );
      } catch (error: any) {
        // alert('ALERT???????' + error.message);
        console.log('GetAuthData Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  authenticateAction: (
    data: IAutheticateViaPasswordInput | IAutheticateViaTokenInput
  ) => new Authenticate().action(data),
  logoutAction: (history?: any) => new Logout().action(history),
  refreshTokenAction: (refreshToken: string, token: string) =>
    new RefreshToken().action({ refreshToken, token }),
  fetchUserDataAction: (payload: IGetAuthDataInput) =>
    new FetchUserData().action(payload),
};
