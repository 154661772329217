import React, { useState } from 'react';
import { StateStatus } from 'redux/utils/common';
import { IUploadedFile } from '.';
import useStyles from './styles';

import docIcon from 'assets/images/icons/doc.png';
import pdfIcon from 'assets/images/icons/pdf.png';
import noImageIcon from 'assets/images/icons/no_image.png';
import LoadingView from 'components/atoms/loading_view/CircularLoading';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';

export interface IFilePreviewProps {
  file: IUploadedFile;
  disabled?: boolean;
  canRemove?: boolean;
  onRemove?: (fileName: any) => void;
  onDownload?: (uploadedFile: IUploadedFile) => Promise<void>;
  margin?: 'none' | 'dense' | 'normal';
  size?: 'small' | 'medium';
}
export function FilePreview({
  file,
  disabled,
  canRemove = true,
  onRemove,
  onDownload,
  margin,
  size,
}: IFilePreviewProps) {
  const classes = useStyles({ margin: margin === 'normal' });
  const [loadState, setLoadState] = useState<StateStatus>(
    isDocument(file.fileName) ? StateStatus.Success : StateStatus.Pending
  );

  // console.log(file);
  if (file?.fileName) {
    return (
      <>
        {loadState == StateStatus.Success && (
          <div className={classes.overlayContainer}>
            {!disabled && canRemove && (
              <IconButton
                onClick={() => {
                  if (onRemove) onRemove(file.fileName);
                }}
                className={clsx(classes.uploadFileButton)}
              >
                <DeleteOutlineOutlinedIcon
                  className={classes.imageContainerButton}
                  style={{ color: 'white' }}
                />
              </IconButton>
            )}
            <IconButton
              onClick={async () => {
                if (onDownload) onDownload(file);
              }}
              className={clsx(classes.uploadFileButton)}
            >
              <GetAppOutlinedIcon
                className={classes.imageContainerButton}
                style={{ color: 'white' }}
              />
            </IconButton>
          </div>
        )}
        <div className={size == 'small' ? classes.small_image : classes.image}>
          {loadState == StateStatus.Pending && !isDocument(file.fileName) ? (
            <LoadingView />
          ) : loadState == StateStatus.Failed ? (
            <img src={noImageIcon} className={classes.imageEl} />
          ) : file.fileName.endsWith('.pdf') ? (
            <img src={pdfIcon} className={classes.imageEl} />
          ) : file.fileName.endsWith('.doc') ||
            file.fileName.endsWith('.docx') ? (
            <img src={docIcon} className={classes.imageEl} />
          ) : (
            <></>
          )}
          <img
            onError={(error) => {
              if (!isDocument(file.fileName)) {
                if (setLoadState) setLoadState(StateStatus.Failed);
              }
            }}
            className={classes.imageEl}
            onLoad={(event) => {
              if (setLoadState) setLoadState(StateStatus.Success);
            }}
            src={file.url}
            style={{
              display:
                loadState != StateStatus.Success || isDocument(file.fileName)
                  ? 'none'
                  : undefined,
            }}
          />
        </div>
      </>
    );
  } else {
    return <></>;
  }
}

function isDocument(fileName: string) {
  return (
    fileName.endsWith('.pdf') ||
    fileName.endsWith('.doc') ||
    fileName.endsWith('.docx')
  );
}
