import TranslatedText from 'components/atoms/translated_text';
import { IOption } from 'components/molecules/form_controls/select';
import React from 'react';
import { LanguagesEnum } from './languages';

export enum RegistrationStatus {
  TEMPORARY = 0,
  SUBMITTED = 1,
  APPROVED = 2,
  REJECTED = 3,
  EXCL = 4,
}

export enum RegistrationState {
  ACTIVE = 'A',
  NEW = 'N',
  UPDATE_REQUEST = 'U',
  HISTORY = 'H',
  EXCLUSION_REQUEST = 'E',
}

export enum SchoolCategory {
  Hotel = '1',
  Gym = '2',
  Fighting_School = '3',
  Swimming_Area = '4',
  Mini_Football_Court = '5',
  Association_or_Club = '6',
  Tennis_Court = '7',
  Diving_School = '8',
  Open_Area = '9',
  Summer_School = '10',
  Other = '11',
  Temporary = '12',
}

export const SchoolCategoryTranslations = {
  [SchoolCategory.Hotel]: {
    [LanguagesEnum.English]: 'Hotel',
    [LanguagesEnum.Greek]: 'Ξενοδοχείο',
  },
  [SchoolCategory.Gym]: {
    [LanguagesEnum.English]: 'Gym',
    [LanguagesEnum.Greek]: 'Γυμναστήριο',
  },
  [SchoolCategory.Fighting_School]: {
    [LanguagesEnum.English]: 'Fighting School',
    [LanguagesEnum.Greek]: 'Σχολή Πολεμικών Τεχνών',
  },
  [SchoolCategory.Swimming_Area]: {
    [LanguagesEnum.English]: 'Swiming Pool',
    [LanguagesEnum.Greek]: 'Κολυμβητικές Δεξαμενές',
  },
  [SchoolCategory.Mini_Football_Court]: {
    [LanguagesEnum.English]: 'Mini Footbal',
    [LanguagesEnum.Greek]: 'Γήπεδα Mini Footbal',
  },
  [SchoolCategory.Association_or_Club]: {
    [LanguagesEnum.English]: 'Association or Club',
    [LanguagesEnum.Greek]: 'Σωματείο',
  },
  [SchoolCategory.Tennis_Court]: {
    [LanguagesEnum.English]: 'Tennis Court',
    [LanguagesEnum.Greek]: 'Γήπεδα Αντισφαίρισης',
  },
  [SchoolCategory.Diving_School]: {
    [LanguagesEnum.English]: 'Diving School',
    [LanguagesEnum.Greek]: 'Καταδυτικές Σχολές',
  },
  [SchoolCategory.Open_Area]: {
    [LanguagesEnum.English]: 'Open Area',
    [LanguagesEnum.Greek]: 'Ανοικτές Εγκαταστάσεις',
  },
  [SchoolCategory.Summer_School]: {
    [LanguagesEnum.English]: 'Summer School',
    [LanguagesEnum.Greek]: 'Καλοκαιρινές Σχολές',
  },
  [SchoolCategory.Other]: {
    [LanguagesEnum.English]: 'Other',
    [LanguagesEnum.Greek]: `'Αλλο`,
  },
  [SchoolCategory.Temporary]: {
    [LanguagesEnum.English]: 'Temporary',
    [LanguagesEnum.Greek]: `Προσωρινή`,
  },
};
