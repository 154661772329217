import Translation from 'config/languages';

export default {
  subject: {
    [Translation.English]: 'Subject',
    [Translation.Greek]: 'Θέμα',
  },
  subject_required: {
    [Translation.English]: 'Please provide E-mail subject',
    [Translation.Greek]: 'Παρακαλώ γράψτε θέμα για το E-mail',
  },
  content: {
    [Translation.English]: 'Content',
    [Translation.Greek]: 'Κείμενο',
  },
  content_required: {
    [Translation.English]: 'Please provide E-mail content',
    [Translation.Greek]: 'Παρακαλώ γράψτε περιεχόμενο για το E-mail',
  },
  mail_success: {
    [Translation.English]: 'E-Mail was sent successfully',
    [Translation.Greek]: 'Το E-Mail έχει σταλεί επιτυχώς',
  },
};
