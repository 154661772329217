// eslint-disable-next-line
import React, { ReactElement } from 'react';
import {
  MenuItem,
  TextField,
  StandardTextFieldProps,
  InputAdornment,
} from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';
import LoadingView from 'components/atoms/loading_view/CircularLoading';
import _ from 'lodash';

interface IIcon {
  position: 'left' | 'right';
  Component: ReactElement;
}

export interface IOption {
  id: string | number;
  label: string | ReactElement;
}

interface Iprops
  extends Omit<
    StandardTextFieldProps,
    'onChange' | 'value' | 'variant' | 'name'
  > {
  includeNone?: boolean;
  options: IOption[];
  control: Control;
  rules: any;
  variant: 'standard' | 'outlined' | 'filled';
  icon?: IIcon;
  loading?: boolean;
  name: string;
  handleChange?: (data: string) => void;
  controllerExtras?: any;
  selectedKey?: string;
}

export default function Select(props: Iprops) {
  const {
    control,
    rules,
    includeNone,
    options,
    icon,
    loading = false,
    handleChange,
    controllerExtras = {},
    InputProps,
    selectedKey = 'label',
    ...other
  } = props;

  return (
    <Controller
      render={({ onChange, value }) => {
        return (
          <TextField
            select
            disabled={loading}
            value={!isNaN(parseInt(value)) ? parseInt(value) : value || ''}
            onChange={(event) => {
              handleChange && handleChange(event.target.value);
              onChange(event);
            }}
            {...other}
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <LoadingView style={{ marginRight: '2em' }} />
                  ) : (
                    icon &&
                    icon.position === 'right' && (
                      <InputAdornment position="start">
                        {icon.Component}
                      </InputAdornment>
                    )
                  )}
                </React.Fragment>
              ),
              ...InputProps,
            }}
          >
            {includeNone && <MenuItem value="">None</MenuItem>}
            {options.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {_.get(item, selectedKey, '')}
              </MenuItem>
            ))}
          </TextField>
        );
      }}
      name={other.name}
      control={control}
      rules={rules}
      {...controllerExtras}
    />
  );
}
