// eslint-disable-next-line
import React from 'react';
import TranslatedText from 'components/atoms/translated_text';
import Translations from './translations';

const RequiredField = () => {
  return <TranslatedText textMap={Translations.requiredField} />;
};

export default { RequiredField };
