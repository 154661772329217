import { useSelector } from 'react-redux';
import { ReducerKeys } from '../../config';
import { LanguagesEnum } from '../../../config/languages';
import { IState } from './reducer';

export const useSelectLanguageID = (): LanguagesEnum => {
  return useSelector(
    (state: any) => (state[ReducerKeys.TRANSL_REDUCER] as IState).language
  );
};
