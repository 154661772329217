// eslint-disable-next-line
import React from 'react';
import clsx from 'clsx';
import * as NavigationSelectors from '../../../../redux/reducers/gl_navigation_reducer/selectors';
import useStyles from '../styles';
import { Routes } from '../../../config/Routes';
import reactLogger from 'utils/logger';
import Configuration from '../configuration';

export default function SidebarContent() {
  const open = NavigationSelectors.useSidebarIsVisible();
  const classes = useStyles({});
  reactLogger.renderPage('SidebarContent');

  return (
    <React.Fragment>
      <div
        className={clsx({
          [classes.content]: true,
          [classes.contentShift]: !Configuration.sidebar.enable || open,
        })}
      >
        <div className={classes.drawerHeader} />
        <RoutesWrapper />
      </div>
    </React.Fragment>
  );
}

const RoutesWrapper = React.memo(() => {
  return <Routes />;
});
