import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]
export const SET_USER_PROFILE: string = typeCreatorV2(
  'UserProfileReducer',
  'SetUserProfile'
);

export const SET_COUNTRIES: string = typeCreatorV2(
  'UserProfileReducer',
  'SetUserProfileCountries'
);

export const EDIT_PROFILE: string = typeCreatorV2(
  'UserProfileReducer',
  'EditProfile'
);
