import { makeStyles, Theme } from '@material-ui/core/styles';
import { CHART_BACKGROUND_COLOR } from 'styles/colors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& > *': {
      // marginTop: theme.spacing(2),
    },
    // backgroundColor: 'green',
  },
  chartContainer: {
    background: CHART_BACKGROUND_COLOR(theme),
  },
  contentContainer: {
    // padding: 0,
    // '&:last-child': {
    //   padding: 0,
    // },
  },
}));

export default useStyles;
// padding: 0px !important;
// height: 26px !important;
// > div {
//   display: inherit !important;
//   padding-right: 0px !important;
// }
