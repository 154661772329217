import _ from 'lodash';
import { showToastAction } from '../../components/atoms/toast_message';
import store from '../../redux/store';
import _axios from './axiosRefreshTokenService';
import nativeAxios from 'axios';
import { ClientError } from 'utils/types';

export const axios = _axios;

interface IOptions {
  body?: any;
  headers?: any;
  showToastMessage?: boolean;
  useMiddleware?: boolean;
}

const request = async (
  method: 'get' | 'post' | 'patch' | 'delete' | 'put',
  url: string,
  options?: IOptions
) => {
  const { body, headers, showToastMessage = false } = options || {};
  const dispatch = store.dispatch;
  try {
    let object = {
      method: method,
      url: url,
      data: body,
      headers: headers || {},
    };

    const result: any = await (options?.useMiddleware === false
      ? nativeAxios(object)
      : axios(object));

    if (result.data.message && showToastMessage) {
      showToastAction(dispatch as any, result.data.message, 'success');
    }
    return result;
  } catch (error: any) {
    if (error.message) {
      showToastAction(
        dispatch as any,
        _.get(error, 'response.data.message', error.message),
        'error'
      );
    }
    throw new ClientError(
      _.get(error, 'response.data.message', error.message),
      {
        statusCode: _.get(error, 'response.status'),
      }
    );
  }
};

export default request;
