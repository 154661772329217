import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as ApplicationTeacherService from 'services/api/application_teacher';

export interface IState {
  applicationTeacherList: {
    data: ApplicationTeacherService.IFetchApplicationTeacherListData | null;
    status: StateStatus | null;
  };
}

const initialState: IState = {
  applicationTeacherList: {
    data: null,
    status: null,
  },
};

const ApplicationTeacherListReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    // SET_DISTRICTS
    case Types.SET_TEACHER_APPLICATIONS:
      return {
        ...state,
        applicationTeacherList: {
          ...state.applicationTeacherList,
          data:
            action.data === KEEP_THE_SAME
              ? state.applicationTeacherList.data
              : action.data,
          status: action.status || null,
        },
      };

    default:
      return {
        ...state,
      };
  }
};

export default ApplicationTeacherListReducer;
