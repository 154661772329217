//  [REDUCER][TYPE]
import { ReducerKeys } from 'redux/config';
import { typeCreatorV2 } from '../../utils/common';

export const FETCH_SCHOOL_OPTIONS: string = typeCreatorV2(
  ReducerKeys.COMMON_REDUCER,
  'FetchSchoolOptions'
);

export const FETCH_TEACHER_OPTIONS: string = typeCreatorV2(
  ReducerKeys.COMMON_REDUCER,
  'FetchTeacherOptions'
);

export const FETCH_USER_OPTIONS: string = typeCreatorV2(
  ReducerKeys.COMMON_REDUCER,
  'FetchUserOptions'
);

export const FETCH_POSTAL_CODE_OPTIONS: string = typeCreatorV2(
  ReducerKeys.COMMON_REDUCER,
  'FetchPostalCodeOptions'
);

export const FETCH_DEPARTMENT_OPTIONS: string = typeCreatorV2(
  ReducerKeys.COMMON_REDUCER,
  'FetchDepartmentOptions'
);
