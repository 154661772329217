// eslint-disable-next-line
import React from 'react';
import { Redirect, withRouter } from 'react-router'; // import the react-router-dom components
import { AuthRoles } from '../../config';
import NavigationConfig from '../../navigation/config';
import reactLogger from 'utils/logger';
import * as AuthSelectors from 'redux/reducers/gl_auth_reducer/selectors';

const MainPage = (props: any) => {
  reactLogger.log('MainPage');
  const roles = AuthSelectors.useSelectUserRoles();

  // replace main page with login Page
  if (roles.includes(AuthRoles.Guest)) {
    return <Redirect to={NavigationConfig.loginPage().path} />;
  } else {
    return <Redirect to={NavigationConfig.applicationListPage().path} />;
  }
};

export default withRouter(MainPage);
