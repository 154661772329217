import { IAction } from 'redux/utils/common';
import * as Types from './types';

interface IState {
  resetPasswordStatus: boolean | null;
}

const initialState: IState = {
  resetPasswordStatus: null,
};

const ResetPasswordReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.RESET_PASSWORD:
      return {
        ...state,
        resetPasswordStatus: action.data.status,
      };
    default:
      return {
        ...state,
      };
  }
};

export default ResetPasswordReducer;
