//  [REDUCER][TYPE]
import { typeCreatorV2 } from 'redux/utils/common';

export const RESET_STORE: string = typeCreatorV2(
  'IndictmentReducer',
  'ResetStore'
);

export const GET_INDICTMENT: string = typeCreatorV2(
  'IndictmentReducer',
  'GetIndictment'
);

export const ADD_EDIT_INDICTMENT: string = typeCreatorV2(
  'IndictmentReducer',
  'AddEditIndictment'
);

export const GET_NEXT_STATUS_OPTIONS: string = typeCreatorV2(
  'IndictmentReducer',
  'GetNextStatusOptions'
);

export const SET_SCHOOL_LIST: string = typeCreatorV2(
  'IndictmentReducer',
  'SetSchoolList'
);

export const FETCH_LATEST_REVIEW: string = typeCreatorV2(
  'IndictmentReducer',
  'FetchLatestReview'
);

export const INSERT_REVIEW: string = typeCreatorV2(
  'IndictmentReducer',
  'InsertReview'
);
