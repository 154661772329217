import { ReducerKeys } from 'redux/config';
import { typeCreatorV2 } from 'redux/utils/common';

//  [REDUCER][TYPE]
export const SET_REVIEW_LIST: string = typeCreatorV2(
  ReducerKeys.REVIEW_HISTORY_REDUCER,
  'SetReviewHistoryList'
);

export const CLEAR_STORE: string = typeCreatorV2(
  ReducerKeys.REVIEW_HISTORY_REDUCER,
  'ClearReviewHistoryStore'
);
