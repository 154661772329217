import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as SchoolService from 'services/api/school';

export interface IState {
  // applicationSchoolList: {
  //   data: ApplicationSchoolService.IFetchApplicationSchoolListData | null;
  //   status: StateStatus | null;
  // };
  schoolList: {
    data: SchoolService.IFetchSchoolListData | null;
    status: StateStatus | null;
  };
}

const initialState: IState = {
  schoolList: {
    data: null,
    status: null,
  },
};

const SchoolListReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    // SET_SCHOOL_FILES
    case Types.SET_SCHOOL_FILES:
      return {
        ...state,
        schoolList: {
          ...state.schoolList,
          data:
            action.data === KEEP_THE_SAME ? state.schoolList.data : action.data,
          status: action.status || null,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default SchoolListReducer;
