// eslint-disable-next-line
import React from 'react';
import { LanguagesEnum } from '../../../config/languages';
import * as LanguageSelectors from '../../../redux/reducers/gl_translations_reducer/selectors';

export interface ITextMap {
  [LanguagesEnum.English]: string;
  [LanguagesEnum.Greek]: string;
}

interface IProps {
  textMap?: ITextMap | null;
  defaultText?: string;
}

export const renderTranslatedText = (
  textMap: ITextMap,
  languageID: LanguagesEnum,
  defaultText?: string
): string => {
  return textMap && typeof textMap === 'object' && languageID in textMap
    ? textMap[languageID]
    : defaultText || '';
};
const TranslatedText = ({
  textMap,
  defaultText = '',
}: IProps): React.ReactElement => {
  const languageID: LanguagesEnum = LanguageSelectors.useSelectLanguageID();

  return (
    <React.Fragment>
      {textMap
        ? renderTranslatedText(textMap, languageID, defaultText)
        : defaultText}
    </React.Fragment>
  );
};

export default TranslatedText;
