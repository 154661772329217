import { useSelector } from 'react-redux';
import { ReducerKeys } from '../../config';
import { AuthRoles } from '../../../config';
import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { Roles } from 'config/authRoles';
import { StateStatus } from 'redux/utils/common';

export const useSelectUserRoles = (): Array<Roles> => {
  return (
    useSelector((state: any) => state[ReducerKeys.AUTH_REDUCER].roles) || [
      AuthRoles.Guest,
    ]
  );
};

export const useSelectUserData = () => {
  const result = useShallowEqualSelector(
    (state: any) => state[ReducerKeys.AUTH_REDUCER].data
  );
  return { username: result?.username, email: result?.email, id: result?.id };
};

export const useSelectAuthStatus = () => {
  const status = useSelector(
    (state: any) => state[ReducerKeys.AUTH_REDUCER].status
  );
  return status;
};

export const useSelectIsLogin = (): null | boolean => {
  const login = useSelector(
    (state: any) => state[ReducerKeys.AUTH_REDUCER].login
  );
  return login;
};

// export const useSelectUserToken = () => {
//   const { token, refreshToken } = useShallowEqualSelector(
//     (state: any) => state[ReducerKeys.AUTH_REDUCER]
//   );

//   return { token, refreshToken };
// };

export const useSelectUserDataStatus = (): StateStatus => {
  return useSelector(
    (state: any) => state[ReducerKeys.AUTH_REDUCER].fetchUserDataStatus
  );
};
