//  [REDUCER][TYPE]
import { typeCreatorV2 } from 'redux/utils/common';

export const SET_TEACHER_SERVICES: string = typeCreatorV2(
  'TeacherDetailsReducer',
  'SetTeacherServices'
);

export const SET_TEACHER_QUALIFICATIONS: string = typeCreatorV2(
  'TeacherDetailsReducer',
  'SetTeacherQualifications'
);

export const SET_TEACHER_EDUCATION: string = typeCreatorV2(
  'TeacherDetailsReducer',
  'SetTeacherEducation'
);

export const SET_TEACHER_WORKING_EXPERIENCE: string = typeCreatorV2(
  'TeacherDetailsReducer',
  'SetTeacherWorkingExperience'
);

export const SET_USER_APPLICATIONS: string = typeCreatorV2(
  'TeacherDetailsReducer',
  'SetUserApplications'
);

export const SET_TEACHER_DETAIL: string = typeCreatorV2(
  'TeacherDetailsReducer',
  'SetTeacherDetail'
);

export const SET_SCHOOLS_LIST: string = typeCreatorV2(
  'TeacherDetailsReducer',
  'SetSchoolsList'
);

export const UPDATE_TEACHER_STATE: string = typeCreatorV2(
  'TeacherDetailsReducer',
  'UpdateTeacherState'
);
