import {
  Box,
  styled,
  TextField,
  TextFieldProps,
  Typography,
} from '@material-ui/core';
import React, { ReactElement } from 'react';

interface IViewOnlyTextFieldProps {
  label?: ReactElement;
  value: string;
  inputProps?: TextFieldProps;
}
export function ViewOnlyTextField({
  label,
  value,
  inputProps,
}: IViewOnlyTextFieldProps) {
  return (
    <>
      <Typography
        style={{
          width: '100%',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {label}
      </Typography>
      <TextField
        // label={label || ''}
        value={value || ''}
        variant={'standard'}
        fullWidth
        margin={'dense'}
        size={'small'}
        // disabled={true}
        inputProps={{
          readOnly: true,
          disabled: true,
        }}
        {...inputProps}
      />
    </>
  );
}
