// eslint-disable-next-line
import React, { ReactElement } from 'react';
import { LatLngExpression, Layer, LeafletEvent } from 'leaflet';
// import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import useStyles from './styles';
import * as parkData from './fake_data/dummy_data.json';
import MapWrapper from './components/map_wrapper';
// import * as countriesData from './fake_data/countries.json';
import * as cyprusData from './fake_data/cyprus.json';

import { MapContainer, GeoJSON, TileLayer } from 'react-leaflet';

interface IProps {
  scrollWheelZoom: boolean;
  height?: number | string;
}

export default function LeafletMap({
  scrollWheelZoom,
  height = '70vh',
}: IProps): ReactElement {
  const classes = useStyles({});
  const position: LatLngExpression = [
    parkData.features[0].geometry.coordinates[1] as number,
    parkData.features[0].geometry.coordinates[0] as number,
  ];

  // const changeCountryColor = (event) => {
  //   event.target.setStyle({
  //     color: "green",
  //     fillColor: this.state.color,
  //     fillOpacity: 1,
  //   });
  // };

  const onEachFeature = (element: any, layer: Layer) => {
    // console.log("Country:", element);
    // layer.options.fillColor = element?.properties?.color || 'red';
    const name = element?.properties?.NAME;
    const confirmedText = element?.properties?.NOTES;
    layer.bindPopup(`${name} ${confirmedText}`);

    layer.on({
      click: (event) => {
        event.target.setStyle({
          color: 'green',
          fillColor: 'blue',
          fillOpacity: 1,
        });
      },
      // zoomanim: (event) => {
      //   event.target.setStyle({
      //     color: 'green',
      //     fillColor: 'blue',
      //     fillOpacity: 1,
      //   });
      // },
      zoomlevelschange: (event: LeafletEvent) => {
        // event.target.
        // event.target.setStyle({
        //   color: 'green',
        //   fillColor: 'blue',
        //   fillOpacity: 1,
        // });
      },
    });
  };

  return (
    <div className={classes.root}>
      <MapWrapper height={height}>
        <MapContainer
          zoom={10}
          scrollWheelZoom={scrollWheelZoom}
          style={{ height: '100%' }}
          center={position}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <GeoJSON
            style={mapStyle}
            data={
              [
                {
                  type: 'Feature',
                  // properties: {},
                  geometry: cyprusData.results[0].geometry,
                  // cyprusData.results[0].geometry as any
                },
                {
                  type: 'Feature',
                  // properties: {},
                  // geometry: cyprusData.results[1].geometry,
                  // cyprusData.results[0].geometry as any
                },
              ] as any
            }
            onEachFeature={onEachFeature}
          />
        </MapContainer>
      </MapWrapper>
    </div>
  );
}

const mapStyle = {
  fillColor: 'white',
  weight: 1,
  color: 'black',
  fillOpacity: 1,
};
