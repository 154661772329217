import { LanguagesEnum } from './languages';

export enum SchoolApplicationStatus {
  S1_TempSave = 1,
  S2_Submitted = 2,
  S3 = 3,
  S4 = 4,
  S5 = 5,
  S6 = 39,
  S7 = 40,
  S8 = 42,
  S9 = 43,
  S10 = 44,
  S11 = 45,
  S12_Approval_Payment = 46,
  S13_Issue_License = 48,
  S_Missing_Info = 10051,
  S_Done_Register = 10052,
  S_Done_Renewal = 10053,
  S_Review_Error = 10054,
}
export enum TeacherApplicationStatus {
  T1_TempSave = 7,
  T2_Submitted = 8,
  T3_Review = 9,
  T4 = 10,
  T5 = 11,
  T6 = 49,
  T8 = 51,
  T_Missing_Info = 10055,
  T_Review_Error = 10056,
  T7 = 10060,
}

export enum IndictmentStatus {}

export const defaultStatuses = {
  school: 1,
  teacher: 7,
  indictment: 13,
};

export interface StatusOption {
  application_status_id: number;
  status: string;
  status_text: string;
}

export function isApplicationSubmitStatus(
  status: SchoolApplicationStatus | TeacherApplicationStatus | IndictmentStatus
) {
  return (
    status == SchoolApplicationStatus.S2_Submitted ||
    status == TeacherApplicationStatus.T2_Submitted
  );
}

export enum ExParteStatus {
  submitted = '1',
  date_of_heading = '2',
  absolute_decision = '3',
  decline_decision = '4',
}

export const ExParteStatusTranslations = {
  [ExParteStatus.submitted]: {
    [LanguagesEnum.English]: 'Submitted',
    [LanguagesEnum.Greek]: 'Καταχωρήθηκε',
  },
  [ExParteStatus.date_of_heading]: {
    [LanguagesEnum.English]: 'Date of Hearing',
    [LanguagesEnum.Greek]: 'Ημερομηνία Ακρόασης',
  },
  [ExParteStatus.absolute_decision]: {
    [LanguagesEnum.English]: 'Decision / Absolute',
    [LanguagesEnum.Greek]: 'Απόφαση / Απόλυτο',
  },
  [ExParteStatus.decline_decision]: {
    [LanguagesEnum.English]: 'Submitted / Rejected',
    [LanguagesEnum.Greek]: 'Απόφαση / Αποσύρθηκε',
  },
};
