// eslint-disable-next-line
import React from 'react';
import { LatLngExpression, Layer } from 'leaflet';
import useStyles from '../../styles';
import MapWrapper from '../../components/map_wrapper';
import {
  MapContainer,
  GeoJSON,
  GeoJSONProps,
  TileLayer,
  useMap,
  // MapConsumer,
} from 'react-leaflet';
import { IGeoJsonFeatures } from './types';
export * from './types';

interface IGeoJsonProps extends Omit<GeoJSONProps, 'onEachFeature' | 'data'> {
  data: IGeoJsonFeatures[];
}

// export interface IGeoJson {}

export interface ILeafletMapProps {
  scrollWheelZoom: boolean;
  height?: number | string;
  geoJsonProps: IGeoJsonProps;
  autoUpdate?: boolean;
  onEachFeature?: (element: any, layer: Layer, ref: any) => void;
  // handleLayer?: {
  //   onClick?: (action: ((data: any) => void), ) => void;
  //   showPopup?: (action: ((data: any) => void)) => void;
  // };
}

const GeoJsonMap = ({
  scrollWheelZoom,
  height = '70vh',
  geoJsonProps,
  onEachFeature,
  autoUpdate = true,
}: ILeafletMapProps): React.ReactElement => {
  const classes = useStyles({});
  const position: LatLngExpression = [0, 0];
  const keyRef = React.useRef(1);
  const mapRef = React.useRef<any>();

  keyRef.current = autoUpdate ? keyRef.current + 1 : keyRef.current;

  return (
    <div className={classes.root}>
      <MapWrapper height={height}>
        <MapContainer
          zoom={3}
          scrollWheelZoom={scrollWheelZoom}
          style={{ height: '100%' }}
          center={position}
        >
          {/* <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          /> */}
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MapConsumer
            onEachFeature={onEachFeature}
            geoJsonProps={geoJsonProps}
            mapRef={mapRef}
            keyRef={keyRef}
          />
        </MapContainer>
      </MapWrapper>
    </div>
  );
};

interface IMapConsumerProps {
  onEachFeature: any;
  geoJsonProps: IGeoJsonProps;
  mapRef: any;
  keyRef: any;
}

const MapConsumer = ({
  onEachFeature,
  geoJsonProps,
  mapRef,
  keyRef,
}: IMapConsumerProps) => {
  const map = useMap();

  React.useEffect(() => {
    if (geoJsonProps.data.length > 0) {
      map.fitBounds(
        geoJsonProps.data.map(({ geometry }) => geometry.coordinates) as any
      );
      map.zoomOut(2);
    }
  }, [geoJsonProps.data, map]);

  return (
    <GeoJSON
      ref={mapRef}
      onEachFeature={
        onEachFeature &&
        ((element, layer) => onEachFeature(element, layer, map))
      }
      style={mapStyle}
      key={keyRef.current}
      {...(geoJsonProps as any)}
    />
  );
};

export default GeoJsonMap;

const mapStyle = {
  fillColor: 'white',
  weight: 1,
  color: 'black',
  innerHeight: '100%',
  fillOpacity: 1,
};
