import Environment from '../../config/environment';
import axios from 'axios';
import { getState } from '../../redux/store';
import { ReducerKeys } from '../../redux/config';

const Configuration = {
  authTokenParser: authTokenParser,
  executeRequestParser: executeRequestParser,
};

interface IParserOutput {
  refreshToken: string;
  token: string;
}

/***  change this block  ************************/
function authTokenParser(requestResponse: IParserData): IParserOutput {
  return {
    refreshToken: requestResponse.data.access_token,
    token: requestResponse.data.access_token,
  };
}

// refreshTokenAction
interface IParserData {
  data: {
    access_token: string;
  };
}
interface IParser {
  data: {
    data: {
      access_token: string;
    };
  };
}

function executeRequestParser(): Promise<IParser> {
  const refreshToken = getState(ReducerKeys.AUTH_REDUCER).refreshToken;
  return axios.get(
    `${Environment.API_BASE_URL}/api/oauth/refresh-token?token=${refreshToken}`
  );
}

/********************************************** */

export default Configuration;
