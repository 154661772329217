import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as TeacherService from 'services/api/teacher';

export interface IState {
  teacherList: {
    data: TeacherService.IFetchTeacherListData | null;
    status: StateStatus | null;
  };
}

const initialState: IState = {
  teacherList: {
    data: null,
    status: null,
  },
};

const TeacherListReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_TEACHER_LIST:
      return {
        ...state,
        teacherList: {
          ...state.teacherList,
          data:
            action.data === KEEP_THE_SAME
              ? state.teacherList.data
              : action.data,
          status: action.status || null,
        },
      };

    default:
      return {
        ...state,
      };
  }
};

export default TeacherListReducer;
