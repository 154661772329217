import { typeCreatorV2 } from '../../../redux/utils/common';

//  [REDUCER][TYPE]
export const SET_TEACHER_SERVICES: string = typeCreatorV2(
  'TeacherApplicationReducer',
  'SetTeacherServices'
);

export const SET_TEACHER_QUALIFICATIONS: string = typeCreatorV2(
  'TeacherApplicationReducer',
  'SetTeacherQualifications'
);

export const SET_TEACHER_EDUCATION: string = typeCreatorV2(
  'TeacherApplicationReducer',
  'SetTeacherEducation'
);

export const SET_TEACHER_WORKING_EXPERIENCE: string = typeCreatorV2(
  'TeacherApplicationReducer',
  'SetTeacherWorkingExperience'
);

export const SET_TEACHER_APPLICATION: string = typeCreatorV2(
  'TeacherApplicationReducer',
  'SetTeacherApplication'
);

export const SET_TEACHER_APPLICATION_BY_ID: string = typeCreatorV2(
  'TeacherApplicationReducer',
  'SetTeacherApplicationById'
);

export const RESET_STORE: string = typeCreatorV2(
  'TeacherApplicationReducer',
  'ResetStore'
);

export const SET_SCHOOL_LIST: string = typeCreatorV2(
  'TeacherApplicationReducer',
  'SetSchoolList'
);

export const GET_NEXT_STATUS_OPTIONS: string = typeCreatorV2(
  'ApplicationSchoolReducer',
  'GetNextStatusOptions'
);

export const REVIEW_APPLICATION: string = typeCreatorV2(
  'TeacherApplicationReducer',
  'ReviewApplication'
);

export const FETCH_LATEST_REVIEW: string = typeCreatorV2(
  'TeacherApplicationReducer',
  'FetchReview'
);

export const CHANGE_APPLICATION_STATUS: string = typeCreatorV2(
  'TeacherApplicationReducer',
  'ChangeApplicationStatus'
);

export const UPDATE_TEACHER_APPLICATION: string = typeCreatorV2(
  'TeacherApplicationReducer',
  'UpdateTeacherApplication'
);
