// eslint-disable-next-line
import React from 'react';
import { SketchPicker, ColorResult } from 'react-color';
import {
  StandardTextFieldProps,
  InputAdornment,
  TextField,
  Menu,
} from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';

interface IIcon {
  position: 'left' | 'right';
  Component: React.ReactElement;
}

interface Iprops
  extends Omit<StandardTextFieldProps, 'onChange' | 'value' | 'variant'> {
  control: Control;
  rules: any;
  variant: 'standard' | 'outlined' | 'filled';
  icon?: IIcon;
  name: string;
  onFocus?: any;
  setValue: any;
  handleChange?: (data: string) => void;
  controllerExtras?: any;
}

export default function ColorPicker(props: Iprops) {
  const {
    control,
    rules,
    icon,
    handleChange,
    controllerExtras = {},
    setValue,
    ...other
  } = props;
  const [color, setColor] = React.useState<any>({
    color: control.defaultValuesRef.current[other.name],
    opa: null,
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose_ = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (
    color: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // console.log('Handle...');
    setValue(other.name, color.hex);
    setColor({
      color: color.hex,
      opa: color.rgb.a,
    });
  };

  return (
    <React.Fragment>
      <Controller
        render={({ onChange, value }) => (
          <TextField
            // value={color?.color || value || ''}
            value={value || ''}
            onChange={(event) => {
              handleChange && handleChange(event.target.value);
              onChange(event);
            }}
            {...other}
            // onFocus={onFocus}
            onClick={handleClick}
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  {icon ? (
                    icon.position === 'right' && (
                      <InputAdornment position="start">
                        {icon.Component}
                      </InputAdornment>
                    )
                  ) : (
                    <InputAdornment position="start">
                      <div
                        style={{
                          padding: 10,
                          background: color?.color,
                          borderRadius: 4,
                        }}
                      />
                    </InputAdornment>
                  )}
                </React.Fragment>
              ),
            }}
          />
        )}
        name={other.name}
        control={control}
        aria-controls="simple-menu"
        aria-haspopup="true"
        rules={rules}
        {...controllerExtras}
      />

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        variant="menu"
        style={{ background: 'transparent' }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose_}
      >
        <SketchPicker
          color={color?.color}
          onChange={handleColorChange}
          // onSwatchHover={(color, e) => {
          //   console.log('color', color);
          // }}
        />
      </Menu>
    </React.Fragment>
  );
}
