export enum Roles {
  Guest = '0',
  Admin = '1',
  User = '2',
  Role3 = '3',
  Role4 = '4',
  Role5 = '5',
  Role6 = '6',
  Role7 = '7',
  Role8 = '8',
  Role9 = '9',
  Role10 = '10',
  Role11 = '11',
  Role12 = '12',
  Role13 = '13',
  Role14 = '14',
  Role15 = '15',
  Role16 = '16',
  Role17 = '17',
  Role18 = '18',
  Role19 = '19',
  Role20 = '20',
}

export const RoleTranslations = {
  [Roles.Guest]: 'ΕΠΙΣΚΕΠΤΗΣ',
  [Roles.Admin]: 'ΔΙΑΧΕΙΡΙΣΤΗΣ',
  [Roles.User]:
    'ΕΞΩΤΕΡΙΚΟΙ ΧΡΗΣΤΕΣ ( ΙΔΙΩΤΙΚΕΣ ΣΧΟΛΕΣ & ΓΥΜΝΑΣΤΕΣ/ΕΚΠΑΙΔΕΥΤΕΣ )',
  [Roles.Role3]: 'ΔΙΕΥΘΥΝΣΗ',
  [Roles.Role4]: 'ΠΡΩΤΟΣ ΛΕΙΤΟΥΡΓΟΣ ΤΟΜΕΑ',
  [Roles.Role5]: 'ΛΕΙΤΟΥΡΓΟΣ ΤΜΗΜΑΤΟΣ ΕΛΕΓΧΟΥ ΙΔΙΩΤΙΚΩΝ ΣΧΟΛΩΝ ΓΥΜΝΑΣΤΙΚΗΣ',
  [Roles.Role6]:
    'ΛΕΙΤΟΥΡΓΟΣ ΤΜΗΜΑΤΟΣ ΕΛΕΓΧΟΥ ΙΔΙΩΤΙΚΩΝ ΣΧΟΛΩΝ ΓΥΜΝΑΣΤΙΚΗΣ ( ΣΥΝΤΟΝΙΣΤΗΣ )',
  [Roles.Role7]: 'ΜΗΧΑΝΙΚΟΙ ΤΕΧΝΙΚΩΝ ΥΠΗΡΕΣΙΩΝ',
  [Roles.Role8]: 'ΤΕΧΝΙΚΟΙ ΕΠΙΘΕΩΡΗΤΕΣ',
  [Roles.Role9]: 'ΕΞΩΤΕΡΙΚΟΙ ΕΠΙΘΕΩΡΗΤΕΣ',
  [Roles.Role10]:
    'ΓΡΑΜΑΤΕΙΑΚΟ ΠΡΟΣΩΠΙΚΟ ΤΜΗΜΑΤΟΣ ΕΛΕΓΧΟΥ ΙΔΙΩΤΙΚΩΝ ΣΧΟΛΩΝ ΓΥΜΝΑΣΤΙΚΗΣ',
  [Roles.Role11]: 'ΛΟΓΙΣΤΗΡΙΟ',
  [Roles.Role12]: 'ΝΟΜΙΚΟΣ ΣΥΜΒΟΥΛΟΣ',
  [Roles.Role13]: 'ΑΡΧΕΙΟ',
  [Roles.Role14]: 'ΔΙΟΙΚΗΤΙΚΟ ΣΥΜΒΟΥΛΙΟ',
  [Roles.Role15]: 'ΤΜΗΜΑ ΑΘΛΗΤΙΚΟΥ ΜΗΤΡΩΟΥ ΚΟΑ',
  [Roles.Role16]:
    'ΚΡΑΤΙΚΕΣ ΥΠΗΡΕΣΙΕΣ ( ΔΗΜΟΤΙΚΕΣ ΑΡΧΕΣ, ΠΥΡΟΣΒΕΣΤΙΚΗ , ΥΓΕΙΟΝΟΜΙΚΕΣ ΥΠΗΡΕΣΙΕΣ κ.λ.π)',
  [Roles.Role17]: 'ΠΑΝΕΠΙΣΤΗΜΙΑΚΑ ΙΔΡΥΜΑΤΑ',
  [Roles.Role18]: 'ΟΜΟΣΠΟΝΔΙΕΣ',
  [Roles.Role19]: 'ΥΠΟΥΡΓΕΙΟ ΠΑΙΔΕΙΑΣ, ΠΟΛΙΤΙΣΜΟΥ, ΑΘΛΗΤΙΣΜΟΥ ΚΑΙ ΝΕΟΛΑΙΑΣ',
  [Roles.Role20]: 'ΥΠΟΥΡΓΕΙΟ ΕΜΠΟΡΙΟΥ',
};

//All - (guest, user)
const Personel = [
  Roles.Admin,
  Roles.Role3,
  Roles.Role4,
  Roles.Role5,
  Roles.Role6,
  Roles.Role7,
  Roles.Role8,
  Roles.Role9,
  Roles.Role10,
  Roles.Role11,
  Roles.Role12,
  Roles.Role13,
  Roles.Role14,
  Roles.Role15,
  Roles.Role16,
  Roles.Role17,
  Roles.Role18,
  Roles.Role19,
  Roles.Role20,
];

const PersonelSecure = [
  Roles.Admin,
  Roles.Role3,
  Roles.Role4,
  Roles.Role5,
  Roles.Role6,
  Roles.Role7,
  Roles.Role8,
  Roles.Role9,
  Roles.Role10,
];

const OutsideSupervisors = [
  Roles.Admin,
  Roles.Role11,
  Roles.Role13,
  Roles.Role14,
  Roles.Role15,
  Roles.Role16,
  Roles.Role17,
  Roles.Role18,
  Roles.Role19,
  Roles.Role20,
];

//All
export const AllUsers = [Roles.Guest, Roles.User, ...Personel];

//All - guest
const RegisteredUsers = [Roles.User, ...Personel];

export default {
  ...Roles,
  All: AllUsers,
  Registered: RegisteredUsers,
  Personel: Personel,
  PersonelSecure: PersonelSecure,
  OutsideSupervisors: OutsideSupervisors,
};
