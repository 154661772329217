// eslint-disable-next-line
import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import * as ThemeSelectors from 'redux/reducers/gl_theme_reducer/selectors';
import * as Configuration from './configuration';
import reactLogger from 'utils/logger';

interface IProps {
  children: React.ReactElement[];
}

export default function ThemeProvider(props: IProps) {
  reactLogger.renderComponent('ThemeProvider');
  const theme = ThemeSelectors.useSelectTheme();

  return (
    <MuiThemeProvider theme={createMuiTheme(Configuration.ThemesConfig[theme])}>
      {props.children}
    </MuiThemeProvider>
  );
}
