import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as ApplicationSchoolService from 'services/api/application_school';
import * as SchoolDepartmentService from 'services/api/school_department';
import * as ApplicationService from 'services/api/application';
import { StatusOption } from 'config/applicationStatus';
import React from 'react';
import actions from './actions';
import { IOption } from 'components/molecules/form_controls/select';

export interface IState {
  addOrEditApplicationSchoolStatus: {
    status: StateStatus | null;
    data: { progress: number; message?: string | React.ReactElement };
  };
  selectedApplicationSchool: {
    data: ApplicationSchoolService.IFetchApplicationSchoolDetailsData | null;
    status: StateStatus | null;
  };
  schoolDepartments: {
    data: SchoolDepartmentService.IFetchDepartmentSchoolListData | null;
    status: StateStatus | null;
  };
  submitStatuses: {
    data: Array<StatusOption> | [];
    status: StateStatus | null;
  };
  teacherRefreshData: {
    data: IOption[];
    status: StateStatus | null;
  };
  reviewApplicationStatus: StateStatus | null;
  latestReviewData: ApplicationService.IFetchLatestReviewData | null;
}

const initialState: IState = {
  addOrEditApplicationSchoolStatus: {
    status: null,
    data: { progress: 0, message: undefined },
  },
  selectedApplicationSchool: {
    data: null,
    status: null,
  },
  schoolDepartments: {
    data: null,
    status: null,
  },
  submitStatuses: {
    data: [],
    status: null,
  },
  teacherRefreshData: {
    data: [],
    status: null,
  },
  reviewApplicationStatus: null,
  latestReviewData: null,
};

const ApplicationSchoolReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    // ADD_OR_EDIT_APPLICATION_SCHOOL
    case Types.ADD_OR_EDIT_APPLICATION_SCHOOL:
      return {
        ...state,
        addOrEditApplicationSchoolStatus: {
          status: action.status || null,
          data: {
            progress: action.data.progress * 100 || 0,
            message: action.data.message,
          },
        },
      };
    // SET_SELECTED_APPLICATION_SCHOOL
    case Types.SET_SELECTED_APPLICATION_SCHOOL:
      return {
        ...state,
        selectedApplicationSchool: {
          ...state.selectedApplicationSchool,
          data:
            action.data === KEEP_THE_SAME
              ? state.selectedApplicationSchool.data
              : action.data,
          status: action.status || null,
        },
      };
    // SET_SCHOOL_DEPARTMENTS
    case Types.SET_SCHOOL_DEPARTMENTS:
      return {
        ...state,
        schoolDepartments: {
          ...state.schoolDepartments,
          data: action.data,
          status: action.status || null,
        },
      };
    case Types.GET_NEXT_STATUS_OPTIONS:
      return {
        ...state,
        submitStatuses: {
          ...state.submitStatuses,
          data:
            action.data === KEEP_THE_SAME
              ? state.submitStatuses.data
              : action.data,
        },
      };
    case Types.RESET_STORE:
      return {
        ...initialState,
      };
    case Types.REVIEW_APPLICATION:
      return {
        ...state,
        reviewApplicationStatus: action.status || null,
      };
    case Types.FETCH_LATEST_REVIEW:
      return {
        ...state,
        latestReviewData: action?.data || null,
      };
    case Types.REFRESH_SCHOOL_TEACHERS:
      return {
        ...state,
        teacherRefreshData: {
          data: action?.data || [],
          status: action?.status || null,
        },
      };

    default:
      return {
        ...state,
      };
  }
};

export default ApplicationSchoolReducer;
