import { IOption } from 'components/molecules/form_controls/select';

export const arraysHasCommonElements = (
  arr1: Array<string | number>,
  arr2: Array<string | number>
): boolean => {
  return arr1.some((item) => arr2.includes(item));
};

export const objectValuesToArray = (enumeration: object): any[] => {
  var arr = [];
  for (var i of Object.values(enumeration)) {
    var type = i;
    arr.push(type);
  }
  return arr;
};
export const enumToArray = objectValuesToArray;

export function arrayAsOptions(valuesArray: any[], labelsArray: string[]) {
  var arr: IOption[] = [];
  for (let index in valuesArray) {
    arr.push({ id: valuesArray[index], label: labelsArray[index] });
  }
  return arr;
}

export function insertAtArray(arr: any[], index: number, ...newItems: any[]) {
  return [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted items
    ...newItems,
    // part of the array after the specified index
    ...arr.slice(index),
  ];
}

export function arraysEqual(a1: [], a2: []) {
  /* WARNING: arrays must not contain {objects} or behavior may be undefined */
  return JSON.stringify(a1) === JSON.stringify(a2);
}
