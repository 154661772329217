import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: ({ margin }: any) => ({
    marginTop: margin ? '0.95rem' : '0.1rem',
    marginBottom: margin ? '0.95rem' : '0.1rem',
  }),
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    justifyContent: 'center',
  },
  uploadFileButton: {
    zIndex: 100,
    borderWidth: 3,
    borderColor: 'white',
    borderStyle: 'solid',
    margin: 5,
  },
  overlayContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    // display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(43, 43, 43, .9)',
    display: 'none',
  },
  small_image: {
    width: 100,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(209, 205, 205, 0.05)',
  },
  image: {
    width: 250,
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(209, 205, 205, 0.05)',
  },
  imageContainer: {
    overflow: 'hidden',
    // padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    borderRadius: 10,
    flexDirection: 'column',
    marginTop: '0.5rem',
    // paddingTop: '0.2rem',
    marginLeft: 5,
    marginRight: 5,
    transition: '0.3s',
    position: 'relative',
    '&:hover': {
      '& $overlayContainer': {
        display: 'flex',
      },
      // '& $image': {
      //   opacity: '0.1 !important',
      // },
    },
  },
  imageEl: {
    width: 'auto',
    height: 'auto',
    maxWidth: '95%',
  },
  imageContainerButton: {
    height: 30,
    width: 30,
    color: 'white',
  },
}));

export default useStyles;
