import { combineReducers } from 'redux';
import AuthReducer from './gl_auth_reducer/reducer';
import TranslationReducer from './gl_translations_reducer/reducer';
import NavigationReducer from './gl_navigation_reducer/reducer';
import ThemeReducer from './gl_theme_reducer/reducer';
import ModalReducer from './gl_modal_reducer/reducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { LOGOUT_TYPE } from './gl_auth_reducer/types';
import CommonReducer from 'redux/reducers/gl_common/reducer';

// ASYNC REDUCERS
import DashboardBuilderReducer from 'samples/dashboardBuilder_page/store/reducer';
import RegisterReducer from 'scenes/register_page/store/reducer';
import UserProfileReducer from 'scenes/profile_page/store/reducer';
import SecurityReducer from 'scenes/security_page/store/reducer';
import ResetPasswordReducer from 'scenes/reset_password/store/reducer';
import ForgotPasswordReducer from 'scenes/forgot_password/store/reducer';
import TeacherApplicationReducer from 'scenes/application_teacher_page/store/reducer';
import ApplicationSchoolReducer from 'scenes/application_school_page/store/reducer';
import ApplicationListReducer from 'scenes/application_list/store/reducer';
import ApplicationSchoolListReducer from 'scenes/application_school_list_page/store/reducer';
import ApplicationTeacherListReducer from 'scenes/application_teacher_list_page/store/reducer';
import TeacherListReducer from 'scenes/teachers_list_page/store/reducer';
import SchoolDetailsReducer from 'scenes/school_details_page/store/reducer';
import SchoolsReducer from 'scenes/school_list_page/store/reducer';
import TeacherDetailsReducer from 'scenes/teacher_details_page/store/reducer';
import IndictmentListReducer from 'scenes/indictment_list_page/store/reducer';
import IndictmentReducer from 'scenes/indictment_page/store/reducer';
import ReportListReducer from 'scenes/reporting_list_page/store/reducer';
import CommunicationsReducer from 'components/app_common/communications_datatable/store/reducer';
import ReviewHistoryReducer from 'components/app_common/review_history/store/reducer';
import UserManagementReducer from 'scenes/user_management_page/store/reducer';
import VisitsListReducer from 'scenes/visits_list_page/store/reducer';
import HelpMePopupReducer from 'scenes/help_me_popup/store/reducer';

import Cookies from 'js-cookie';
import { ReducerKeys } from '../config';

// const authPersistConfig = {
//   key: ReducerKeys.AUTH_REDUCER,
//   storage: storage,
//   blacklist: ['status'],
// };

const translationPersistConfig = {
  key: ReducerKeys.TRANSL_REDUCER,
  storage: storage,
};

const navigationPersistConfig = {
  key: ReducerKeys.NAVIGATION_REDUCER,
  storage: storage,
};

const themePersistConfig = {
  key: ReducerKeys.THEME_REDUCER,
  storage: storage,
  // blacklist: ['accessFromMobileApp'],
};

export const asyncReducers = {
  [ReducerKeys.DASHBOARD_BUILDER_REDUCER]: DashboardBuilderReducer,
  [ReducerKeys.REGISTER_REDUCER]: RegisterReducer,
  [ReducerKeys.USER_PROFILE_REDUCER]: UserProfileReducer,
  [ReducerKeys.CHANGE_PASSWORD_REDUCER]: SecurityReducer,
  [ReducerKeys.RESET_PASSOWRD_REDUCER]: ResetPasswordReducer,
  [ReducerKeys.FORGOT_PASSWORD_REDUCER]: ForgotPasswordReducer,
  [ReducerKeys.TEACHER_APPLICATION_REDUCER]: TeacherApplicationReducer,
  [ReducerKeys.APPLICATION_SCHOOL_REDUCER]: ApplicationSchoolReducer,
  [ReducerKeys.USER_APPLICATION_LIST_REDUCER]: ApplicationListReducer,
  [ReducerKeys.APPLICATION_SCHOOL_LIST_REDUCER]: ApplicationSchoolListReducer,
  [ReducerKeys.APPLICATION_TEACHER_LIST_REDUCER]: ApplicationTeacherListReducer,
  [ReducerKeys.TEACHER_LIST_REDUCER]: TeacherListReducer,
  [ReducerKeys.SCHOOL_DETAILS_REDUCER]: SchoolDetailsReducer,
  [ReducerKeys.SCHOOLS_REDUCER]: SchoolsReducer,
  [ReducerKeys.TEACHER_DETAILS_REDUCER]: TeacherDetailsReducer,
  [ReducerKeys.INDICTMENT_LIST_REDUCER]: IndictmentListReducer,
  [ReducerKeys.INDICTMENT_REDUCER]: IndictmentReducer,
  [ReducerKeys.REPORT_LIST_REDUCER]: ReportListReducer,
  [ReducerKeys.COMMUNICATIONS_REDUCER]: CommunicationsReducer,
  [ReducerKeys.REVIEW_HISTORY_REDUCER]: ReviewHistoryReducer,
  [ReducerKeys.USERS_REDUCER]: UserManagementReducer,
  [ReducerKeys.VISITS_REDUCER]: VisitsListReducer,
};

export const staticReducers = {
  // global
  [ReducerKeys.AUTH_REDUCER]: AuthReducer,
  [ReducerKeys.TRANSL_REDUCER]: persistReducer(
    translationPersistConfig,
    TranslationReducer
  ),
  [ReducerKeys.NAVIGATION_REDUCER]: persistReducer(
    navigationPersistConfig,
    NavigationReducer
  ),
  [ReducerKeys.THEME_REDUCER]: persistReducer(themePersistConfig, ThemeReducer),
  [ReducerKeys.MODAL_REDUCER]: ModalReducer,
  [ReducerKeys.COMMON_REDUCER]: CommonReducer,
  [ReducerKeys.HELP_ME_POPUP_REDUCER]: HelpMePopupReducer,
};

// export default function createReducer(asyncReducers = {}, store = {}) {
//   return combineReducers({
//     ...staticReducers,
//     ...asyncReducers,
//   });
// }

// This is like your own combineReducers() with custom behavior
// function _combineReducers(dynamicReducer: any) {
//   // Creates a reducer from the main and a dynamic reducer
//   return function (state: any, action: any) {
//     // Calculate main state
//     let nextState: any = staticReducers;

//     // If specified, calculate dynamic reducer state
//     if (dynamicReducer) {
//       nextState[dynamicReducer.name] = dynamicReducer.reducer(
//         nextState[dynamicReducer.name],
//         action
//       );
//     }

//     return nextState;
//   };
// }

// export default function createReducer(asyncReducers = {}, store = {}) {
//   return combineReducers({
//     ...staticReducers,
//     ...asyncReducers,
//   });
// }

// export default
function createReducer(asyncReducers = {}, store = {}) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });
}

const rootReducer =
  (asyncReducers = {}, store = {}) =>
  (state: any, action: any) => {
    if (action.type === LOGOUT_TYPE) {
      // for all keys defined in your persistConfig(s)
      storage.removeItem('persist:root');
      Cookies.remove('token');
      Cookies.remove('refreshToken');
      Cookies.remove('error');

      console.log('LOGOUT USER !!!!!!!!!!');

      return createReducer(asyncReducers, store)(undefined, action);
    }
    return createReducer(asyncReducers, store)(state, action);
  };

export default rootReducer;
