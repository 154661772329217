import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as IndictmentService from 'services/api/indictment';
import * as SchoolService from 'services/api/school';
import { defaultStatuses, StatusOption } from 'config/applicationStatus';

const defaultValues: IndictmentService.IIndictmentData = {
  indictment_id: '',
  comments: '',
  school_id: '',
  legal_advisor_date_sent: new Date(),
  criminal_case_number: '',
  folder_return_date: new Date(),
  folder_returned: false,
  defendants: '',
  witnesses: '',
  status_id: defaultStatuses.indictment,
  status: '',
  status_text: '',
  created_at: '',
  created_by: '',
  modified_at: '',
  modified_by: '',
  school_name: '',
  can_edit: true,
  can_review: true,
  can_view: true,
  ex_parte_date: null,
  ex_parte_comments: '',
  ex_parte_status: null,
};

export interface ReviewData {
  status_id: number;
  comment: string;
}

interface IReviewData {
  data: Array<ReviewData>;
}

export interface IState {
  indictment: {
    data: IndictmentService.IIndictmentData | null;
    status: StateStatus | null;
  };
  addEditIndictment: StateStatus | null;
  nextStatuses: Array<StatusOption> | null;
  schools: {
    data: SchoolService.IFetchSchoolOptionsData | null;
    status: StateStatus | null;
  };
  latestReviewData: IReviewData | null;
}

const initialState: IState = {
  indictment: { data: defaultValues, status: null },
  addEditIndictment: null,
  nextStatuses: null,
  schools: {
    data: null,
    status: null,
  },
  latestReviewData: null,
};

const IndictmentReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.RESET_STORE:
      return {
        ...initialState,
      };
    case Types.GET_INDICTMENT:
      return {
        ...state,
        indictment: {
          data: action.data === KEEP_THE_SAME ? state.indictment : action.data,
          status: action.status || null,
        },
      };
    case Types.ADD_EDIT_INDICTMENT:
      return {
        ...state,
        addEditIndictment: action.status || null,
      };
    case Types.GET_NEXT_STATUS_OPTIONS:
      return {
        ...state,
        nextStatuses:
          action.data === KEEP_THE_SAME ? state.nextStatuses : action?.data,
      };
    case Types.SET_SCHOOL_LIST:
      return {
        ...state,
        schools: {
          ...state.schools,
          data:
            action.data === KEEP_THE_SAME ? state.schools.data : action.data,
          status: action.status || null,
        },
      };
    case Types.FETCH_LATEST_REVIEW:
      return {
        ...state,
        latestReviewData:
          action.data === KEEP_THE_SAME ? state.latestReviewData : action.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default IndictmentReducer;
