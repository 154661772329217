//  [REDUCER][TYPE]
import { typeCreatorV2 } from 'redux/utils/common';

export const FETCH_EVALUATION_REVIEWS: string = typeCreatorV2(
  'ApplicationSchoolReducer',
  'FetchEvaluationReviews'
);

export const ADD_OR_EDIT_APPLICATION_SCHOOL: string = typeCreatorV2(
  'ApplicationSchoolReducer',
  'AddOrEditApplicationSchool'
);

export const CHANGE_APPLICATION_STATUS: string = typeCreatorV2(
  'ApplicationSchoolReducer',
  'ChangeApplicationStatus'
);

export const SET_SELECTED_APPLICATION_SCHOOL: string = typeCreatorV2(
  'ApplicationSchoolReducer',
  'SetSelectedApplicationSchool'
);

export const SET_SCHOOL_DEPARTMENTS: string = typeCreatorV2(
  'ApplicationSchoolReducer',
  'SetSchoolDepartments'
);

export const RESET_STORE: string = typeCreatorV2(
  'ApplicationSchoolReducer',
  'ResetStore'
);

export const GET_NEXT_STATUS_OPTIONS: string = typeCreatorV2(
  'ApplicationSchoolReducer',
  'GetNextStatusOptions'
);

export const REVIEW_APPLICATION: string = typeCreatorV2(
  'ApplicationSchoolReducer',
  'ReviewApplication'
);

export const FETCH_LATEST_REVIEW: string = typeCreatorV2(
  'ApplicationSchoolReducer',
  'FetchLatestReview'
);

export const SEND_VERIFICATION_EMAILS: string = typeCreatorV2(
  'ApplicationSchoolReducer',
  'SendVerificationEmail'
);

export const REFRESH_SCHOOL_TEACHERS: string = typeCreatorV2(
  'ApplicationSchoolReducer',
  'RefreshSchoolTeachers'
);
