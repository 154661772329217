import { IOption } from 'components/molecules/form_controls/select';
import { IAction, KEEP_THE_SAME, StateStatus } from '../../utils/common';
import {
  FETCH_DEPARTMENT_OPTIONS,
  FETCH_POSTAL_CODE_OPTIONS,
  FETCH_SCHOOL_OPTIONS,
  FETCH_TEACHER_OPTIONS,
  FETCH_USER_OPTIONS,
} from './types';

export interface IState {
  school_options: {
    data: IOption[];
    status: StateStatus | null;
  };
  teacher_options: {
    data: IOption[];
    status: StateStatus | null;
  };
  user_options: {
    data: IOption[];
    status: StateStatus | null;
  };
  postal_code_options: {
    data: IOption[];
    status: StateStatus | null;
  };
  department_options: {
    data: IOption[];
    status: StateStatus | null;
  };
}

const initialState: IState = {
  school_options: {
    data: [],
    status: null,
  },
  teacher_options: {
    data: [],
    status: null,
  },
  user_options: {
    data: [],
    status: null,
  },
  postal_code_options: {
    data: [],
    status: null,
  },
  department_options: {
    data: [],
    status: null,
  },
};

const CommonReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case FETCH_SCHOOL_OPTIONS:
      return {
        ...state,
        school_options: {
          data:
            action.data === KEEP_THE_SAME
              ? state.school_options.data
              : action.data,
          status: action.status || null,
        },
      };
    case FETCH_TEACHER_OPTIONS:
      return {
        ...state,
        teacher_options: {
          data:
            action.data === KEEP_THE_SAME
              ? state.teacher_options.data
              : action.data,
          status: action.status || null,
        },
      };
    case FETCH_USER_OPTIONS:
      return {
        ...state,
        user_options: {
          data:
            action.data === KEEP_THE_SAME
              ? state.user_options.data
              : action.data,
          status: action.status || null,
        },
      };
    case FETCH_POSTAL_CODE_OPTIONS:
      const pcodes: IOption[] = [];
      if (action.status === StateStatus.Success) {
        for (let i in action.data) {
          const item = {
            label: '' + action.data[i].postal_code,
            id: '' + i,
          };
          pcodes.push(item);
        }
      }
      return {
        ...state,
        postal_code_options: {
          data:
            action.data === KEEP_THE_SAME
              ? state.postal_code_options.data
              : pcodes,
          status: action.status || null,
        },
      };
    case FETCH_DEPARTMENT_OPTIONS:
      return {
        ...state,
        department_options: {
          data:
            action.data === KEEP_THE_SAME
              ? state.department_options.data
              : action.data,
          status: action.status || null,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default CommonReducer;
