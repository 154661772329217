import useShallowEqualSelector from '../../../custom_hooks/useShallowEqualsSelector';
import { ReducerKeys } from 'redux/config';
import { IState } from './reducer';
import { useSelector } from 'react-redux';

export const useHelpMePopupOpen = (): IState['open'] => {
  const data = useShallowEqualSelector(
    (state: any) => (state[ReducerKeys.HELP_ME_POPUP_REDUCER] as IState).open
  );
  return data;
};
