// eslint-disable-next-line
import React from 'react';
import { TextField, StandardTextFieldProps } from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface Iprops extends StandardTextFieldProps {
  options: Array<any>;
  control: Control;
  rules: any;
  selectedKey: string;
  name: string;
  handleChange?: (data: string) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
  controllerExtras?: any;
  style?: React.CSSProperties;
}

export default function AutoCompleteSelect(props: Iprops) {
  const {
    control,
    rules,
    options,
    handleChange,
    onBlur,
    selectedKey,
    style,
    id,
    controllerExtras = {},
    ...other
  } = props;

  return (
    <Controller
      render={(props) => {
        // console.log(id, props.value);
        return (
          <Autocomplete
            {...props}
            onBlur={onBlur}
            disabled={other.disabled}
            options={options}
            getOptionLabel={(option) => {
              return (
                `${option[selectedKey] ? option[selectedKey] : option}` || ''
              );
            }}
            getOptionSelected={(option, value) => option[selectedKey] === value}
            renderOption={(option) => {
              return (
                `${option[selectedKey] ? option[selectedKey] : option}` || ''
              );
            }}
            renderInput={(params) => {
              return <TextField variant="outlined" {...params} {...other} />;
            }}
            onChange={(__, data) => {
              const result =
                data && data[selectedKey] ? data[selectedKey] : null;
              handleChange && handleChange(result || '');
              props.onChange(result);
            }}
          />
        );
      }}
      name={other.name}
      control={control}
      rules={rules}
      {...controllerExtras}
    />
  );
}
