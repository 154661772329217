// eslint-disable-next-line
import React, { ReactElement } from 'react';
import {
  StandardTextFieldProps,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';

interface IIcon {
  position: 'left' | 'right';
  Component: ReactElement;
}

interface Iprops
  extends Omit<StandardTextFieldProps, 'onChange' | 'value' | 'variant'> {
  control: Control;
  rules: any;
  variant: 'standard' | 'outlined' | 'filled';
  icon?: IIcon;
  name: string;
  onFocus?: any;
  controllerExtras?: any;
}

export default function TTextField(props: Iprops) {
  const { control, icon, rules, controllerExtras = {}, ...other } = props;

  return (
    <Controller
      render={({ value, onChange, ...rest }) => (
        <TextField
          value={value || ''}
          onChange={onChange}
          {...rest}
          {...other}
          InputProps={{
            endAdornment: (
              <React.Fragment>
                {icon && icon.position === 'right' && (
                  <InputAdornment position="start">
                    {icon.Component}
                  </InputAdornment>
                )}
              </React.Fragment>
            ),
          }}
        />
      )}
      name={other.name}
      control={control}
      rules={rules}
      {...controllerExtras}
    />
  );
}
