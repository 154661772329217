import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as TeacherService from 'services/api/teacher';
import * as ApplicationService from 'services/api/application';
import {
  ITeacherApplicationEducation,
  ITeacherApplicationQualification,
  ITeacherApplicationServices,
  ITeacherApplicationWorkingExperience,
} from 'services/api/application_teacher';

export interface IState {
  services: Array<ITeacherApplicationServices>;
  qualifications: Array<ITeacherApplicationQualification>;
  education: Array<ITeacherApplicationEducation>;
  workingExperience: Array<ITeacherApplicationWorkingExperience>;
  applicationUserList: {
    data: ApplicationService.IFetchUserApplicationsData | null;
    status: StateStatus | null;
  };
  teacherDetail: {
    data: TeacherService.ITeacher | null;
    status: StateStatus | null;
  };
  schoolsList: {
    data: TeacherService.IFetchTeacherSchoolsListData | null;
    status: StateStatus | null;
  };
}

const initialState: IState = {
  services: [],
  qualifications: [],
  education: [],
  workingExperience: [],
  applicationUserList: {
    data: null,
    status: null,
  },
  teacherDetail: {
    data: null,
    status: null,
  },
  schoolsList: {
    data: null,
    status: null,
  },
};

const TeacherDetailReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_USER_APPLICATIONS:
      return {
        ...state,
        applicationUserList: {
          ...state.applicationUserList,
          data:
            action.data === KEEP_THE_SAME
              ? state.applicationUserList.data
              : action.data,
          status: action.status || null,
        },
      };
    case Types.SET_TEACHER_DETAIL:
      // console.log(action.data);
      return {
        ...state,
        services: action.data.services,
        qualifications: action.data.qualifications,
        education: action.data.education,
        workingExperience: action.data.working_experience,
        teacherDetail: {
          ...state.teacherDetail,
          data: action.data,
          status: action.status || null,
        },
      };
    case Types.SET_SCHOOLS_LIST:
      return {
        ...state,
        schoolsList: {
          ...state.schoolsList,
          data:
            action.data === KEEP_THE_SAME
              ? state.schoolsList.data
              : action.data,
          status: action.status || null,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default TeacherDetailReducer;
