// eslint-disable-next-line
import React from 'react';
import AuthActions from 'redux/reducers/gl_auth_reducer/actions';
import { useDispatch } from 'react-redux';
import useQuery from 'custom_hooks/useQuery';
import ThemeActions from 'redux/reducers/gl_theme_reducer/actions';
import TranslationActions from 'redux/reducers/gl_translations_reducer/actions';
import store from 'redux/store';

export interface IWebviewProviderProps {
  children: React.ReactNode;
}

const WebviewProvider = ({ children }: IWebviewProviderProps) => {
  useWebview();

  return <React.Fragment>{children}</React.Fragment>;
};

const useWebview = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  // const webViewAccess = query.get('webViewAccess') === 'true';
  const webViewAccess =
    query.get('webViewAccess') === 'true' ||
    (store.getState().ThemeReducer.accessFromMobileApp &&
      !query.get('webViewAccess'));

  React.useEffect(() => {
    if (webViewAccess) {
      window.addEventListener('message', (message) => {
        console.log('Get messages from webview...:', message);
        // alert('Get messages from webview...:');

        try {
          const data = message.data;
          if ('themeMode' in data) {
            dispatch(ThemeActions.changeThemeAction(data.themeMode as any));
          }

          if ('lan' in data) {
            dispatch(TranslationActions.changeLanguageAction(data.lan as any));
          }

          if ('logout' in data) {
            alert('Logout!!..');
            dispatch(AuthActions.logoutAction());
          }
          // ... add more events..
        } catch (ex: any) {
          alert('Error..' + ex.message);
        }
      });
      // dome is ready
      (window as any)?.ReactNativeWebView &&
        (window as any).ReactNativeWebView.postMessage('READY');
    }

    // return () => {
    //   if (webViewAccess) {
    //     dispatch(AuthActions.logoutAction());
    //   }
    // };
  }, [dispatch, webViewAccess]);

  React.useLayoutEffect(() => {
    dispatch(ThemeActions.setAccessAction(webViewAccess));
  }, [dispatch, webViewAccess]);
};

export default React.memo(WebviewProvider);
