// eslint-disable-next-line
import React from 'react';
import { Switch, Route } from 'react-router-dom'; // import the react-router-dom components
import NavigationConfig, { Appbars } from '.';
import { RequireAuth, WithNavbar } from '../middlewares';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import AuthRoles, { Roles } from '../../config/authRoles';
import * as ArrayUtils from 'utils/arrays';
import Translations from '../translations';
import TranslatedText from 'components/atoms/translated_text';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PersonIcon from '@material-ui/icons/Person';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import PersonSearchIcon from '@material-ui/icons/Assignment';

interface IRoutes {
  exact: boolean;
  path: string;
  component: any;
  roles: Array<string | number>;
  // any | Roles[];
}

interface IProps {
  routes: IRoutes[];
}

const StackNavigator = ({ routes }: IProps) => {
  // console.log('Stack naviator...');
  return (
    <Switch>
      {routes.map((route, i) => (
        <Route
          key={i}
          exact={route.exact}
          path={route.path}
          render={() => (
            <RequireAuth Component={route.component} roles={route.roles} />
          )}
        />
      ))}
    </Switch>
  );
};

export const MainRoutes = () => {
  return (
    <main>
      <Switch>
        {Appbars.map((route, i) => (
          <Route
            key={i}
            exact={false}
            path={'/'}
            render={() => (
              <WithNavbar
                include={route.include}
                exclude={route.exclude}
                select={route.select}
                Component={route.component}
              />
            )}
          />
        ))}
      </Switch>
    </main>
  );
};

/** CHANGE THIS TO PUT NEW ROUTES */
export const Routes = React.memo(() => {
  return (
    <main>
      <StackNavigator
        routes={[
          { ...NavigationConfig.schoolDetailsPage(), exact: true },
          { ...NavigationConfig.teacherDetailsPage(), exact: true },
          { ...NavigationConfig.schoolListPage(), exact: true },
          { ...NavigationConfig.profilePage(), exact: true },
          { ...NavigationConfig.applicationListPage(), exact: true },
          { ...NavigationConfig.aplicationSchoolListPage(), exact: true },
          { ...NavigationConfig.aplicationTeacherListPage(), exact: true },

          // { ...NavigationConfig.datatablesPage(), exact: true },
          { ...NavigationConfig.securityPage(), exact: true },
          { ...NavigationConfig.registerPage(), exact: true },
          // { ...NavigationConfig.registerNoAuthPage(), exact: true },
          { ...NavigationConfig.formsPage(), exact: true },
          { ...NavigationConfig.applicationSchoolPage(), exact: true },
          { ...NavigationConfig.applicationTeacherPage(), exact: true },
          { ...NavigationConfig.addTeacherApplicationPage(), exact: true },
          { ...NavigationConfig.addSchoolApplicationPage(), exact: true },
          { ...NavigationConfig.teachersListPage(), exact: true },
          { ...NavigationConfig.indictmentListPage(), exact: true },
          { ...NavigationConfig.indictmentPage(), exact: true },
          { ...NavigationConfig.userCalendarPage(), exact: true },
          { ...NavigationConfig.userManagementPage(), exact: true },
          { ...NavigationConfig.visitsListPage(), exact: true },
          { ...NavigationConfig.paymentLangingPage(), exact: true },
          { ...NavigationConfig.reportingListPage(), exact: true },

          { ...NavigationConfig.loginPage(), exact: true },
          // { ...NavigationConfig.loginNoAuthPage(), exact: true },

          { ...NavigationConfig.mainPage(), exact: true },

          { ...NavigationConfig.forgotPasswordPage(), exact: true },
          // { ...NavigationConfig.forgotPasswordNoAuthPage(), exact: true },

          { ...NavigationConfig.resetPasswordPage(), exact: true },
          // { ...NavigationConfig.dashboardBuilderPage(), exact: true },
          // { ...NavigationConfig.chartsPage(), exact: true },
          // { ...NavigationConfig.mapsPage(), exact: true },
          // { ...NavigationConfig.mobileEntrypointPage(), exact: true },

          { ...NavigationConfig.rootPage(), exact: true }, // always last
        ]}
      />
    </main>
  );
});

interface ISubItems {
  path: string;
  title: string | React.ReactElement;
  component: React.FC;
  id: number;
  requiredRoles: Array<number | string>;
  showOpenInNewWindowIcon?: boolean;
}
interface ISidebarConfig {
  title: string | React.ReactElement;
  path: string | null;
  Icon: React.FC;
  id: number;
  component: React.FC | null;
  subItems?: ISubItems[];
  requiredRoles: Array<number | string> | null;
  showOpenInNewWindowIcon?: boolean;
}

export const SideBarConfig: ISidebarConfig[] = [
  {
    component: NavigationConfig.applicationListPage().component,
    path: NavigationConfig.applicationListPage().path,
    id: 1,
    Icon: () => <AssignmentTurnedInIcon />,
    title: <TranslatedText textMap={Translations.sidebarApplications} />,
    requiredRoles: NavigationConfig.applicationListPage().roles,
  },
  {
    title: <TranslatedText textMap={Translations.sidebarAdministration} />,
    path: null,
    component: null,
    requiredRoles: [...AuthRoles.PersonelSecure, AuthRoles.Role12],
    Icon: () => <AssessmentOutlinedIcon />,
    id: 2,
    subItems: [
      {
        component: NavigationConfig.aplicationSchoolListPage().component,
        path: NavigationConfig.aplicationSchoolListPage().path,
        id: 1,
        //  'School List'
        title: (
          <TranslatedText textMap={Translations.sidebarApplicationsSchools} />
        ),
        requiredRoles: NavigationConfig.aplicationSchoolListPage().roles,
        showOpenInNewWindowIcon: true,
      },
      {
        component: NavigationConfig.aplicationTeacherListPage().component,
        path: NavigationConfig.aplicationTeacherListPage().path,
        id: 2,
        title: (
          <TranslatedText textMap={Translations.sidebarApplicationsTeachers} />
        ),
        requiredRoles: NavigationConfig.aplicationTeacherListPage().roles,
        showOpenInNewWindowIcon: true,
      },
      {
        component: NavigationConfig.indictmentListPage().component,
        path: NavigationConfig.indictmentListPage().path,
        id: 3,
        title: <TranslatedText textMap={Translations.sidebarIndictments} />,
        requiredRoles: NavigationConfig.indictmentListPage().roles,
        showOpenInNewWindowIcon: true,
      },
      {
        component: NavigationConfig.userManagementPage().component,
        path: NavigationConfig.userManagementPage().path,
        id: 4,
        title: (
          <TranslatedText textMap={Translations.sidebarUserManagementPage} />
        ),
        requiredRoles: NavigationConfig.userManagementPage().roles,
        showOpenInNewWindowIcon: true,
      },
      {
        component: NavigationConfig.reportingListPage().component,
        path: NavigationConfig.reportingListPage().path,
        id: 5,
        title: <TranslatedText textMap={Translations.sidebarReportingPage} />,
        requiredRoles: NavigationConfig.reportingListPage().roles,
        showOpenInNewWindowIcon: true,
      },
    ],
    // component:
    // NavigationConfig.applicationSchoolPage().component,
  },
  {
    component: NavigationConfig.schoolListPage().component,
    path: NavigationConfig.schoolListPage().path,
    id: 3,
    Icon: () => <FitnessCenterIcon />,
    title: <TranslatedText textMap={Translations.sidebarSchools} />,
    requiredRoles: NavigationConfig.schoolListPage().roles,
    showOpenInNewWindowIcon: true,
  },
  {
    component: NavigationConfig.teachersListPage().component,
    path: NavigationConfig.teachersListPage().path,
    id: 4,
    Icon: () => <PersonIcon />,
    title: <TranslatedText textMap={Translations.sidebarTeachers} />,
    requiredRoles: NavigationConfig.teachersListPage().roles,
    showOpenInNewWindowIcon: true,
  },
  {
    component: NavigationConfig.userCalendarPage().component,
    path: NavigationConfig.userCalendarPage().path,
    id: 5,
    Icon: () => <CalendarIcon />,
    title: <TranslatedText textMap={Translations.sidebarUserCalendarPage} />,
    requiredRoles: NavigationConfig.userCalendarPage().roles,
    showOpenInNewWindowIcon: true,
  },
  {
    component: NavigationConfig.visitsListPage().component,
    path: NavigationConfig.visitsListPage().path,
    id: 6,
    Icon: () => <PersonSearchIcon />,
    title: <TranslatedText textMap={Translations.sidebarVisitsListPage} />,
    requiredRoles: NavigationConfig.visitsListPage().roles,
    showOpenInNewWindowIcon: true,
  },
];

function filterSubElements(
  subElements: ISubItems[],
  roles: Array<Roles>
): ISubItems[] {
  // return (
  //   subElements.filter(({ requiredRoles }) => requiredRoles.includes(roles)) ||
  //   []
  // );
  return (
    subElements.filter(({ requiredRoles }) =>
      ArrayUtils.arraysHasCommonElements(requiredRoles, roles)
    ) || []
  );
}

export const getNavbarConfig = (
  roles: Array<Roles> | null
): ISidebarConfig[] => {
  return (
    Array.isArray(roles)
      ? SideBarConfig.filter(
          ({ requiredRoles }) =>
            Array.isArray(requiredRoles) &&
            ArrayUtils.arraysHasCommonElements(requiredRoles, roles)
        )
      : []
  ).map((element) => ({
    ...element,
    subItems: Array.isArray(element.subItems)
      ? filterSubElements(element.subItems, roles || [])
      : element.subItems,
  }));
};
