import { typeCreatorV2 } from '../../../redux/utils/common';

//  [REDUCER][TYPE]
export const SET_USERS_LIST: string = typeCreatorV2(
  'UsersReducer',
  'SetUsersList'
);

export const SET_ADD_USERS: string = typeCreatorV2(
  'UsersReducer',
  'SetAddUsers'
);

export const SET_EDIT_USERS: string = typeCreatorV2(
  'UsersReducer',
  'SetEditUsers'
);

export const SET_USERS_DETAIL: string = typeCreatorV2(
  'UsersReducer',
  'SetUsersDetail'
);

export const SEARCH_USERS: string = typeCreatorV2(
  'UsersReducer',
  'SearchUsers'
);

export const SET_ROLE: string = typeCreatorV2('UsersReducer', 'SetRole');
