import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as ApplicationSchoolService from 'services/api/application_school';
import * as SchoolService from 'services/api/school';
import { IFetchIndictmentListData } from 'services/api/indictment';

export interface IState {
  applicationSchoolList: {
    data: ApplicationSchoolService.IFetchApplicationSchoolListData | null;
    status: StateStatus | null;
  };
  schoolList: {
    data: SchoolService.IFetchSchoolListData | null;
    status: StateStatus | null;
  };
  schoolBasicData: {
    data: SchoolService.ISchoolDetailsData | null;
    status: StateStatus | null;
    // data: SchoolService.ISchoolDetailsData | null;
    // status: StateStatus | null;
  };
  schoolTeachersList: {
    data: ApplicationSchoolService.IFetchSchoolTeacherListData | null;
    status: StateStatus | null;
  };
  indictmentList: {
    data: IFetchIndictmentListData | null;
    status: StateStatus | null;
  };
  reassignUserStatus: StateStatus | null;
}

const initialState: IState = {
  applicationSchoolList: {
    data: null,
    status: null,
  },
  schoolList: {
    data: null,
    status: null,
  },
  schoolBasicData: {
    data: null,
    status: null,
  },
  schoolTeachersList: {
    data: null,
    status: null,
  },
  indictmentList: {
    data: null,
    status: null,
  },
  reassignUserStatus: null,
};

const ApplicationSchoolDetailsReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    // SET_SCHOOL_APPLICATIONS
    case Types.SET_SCHOOL_APPLICATIONS:
      return {
        ...state,
        applicationSchoolList: {
          ...state.applicationSchoolList,
          data:
            action.data === KEEP_THE_SAME
              ? state.applicationSchoolList.data
              : action.data,
          status: action.status || null,
        },
      };
    // SET_SCHOOL_FILES
    case Types.SET_SCHOOL_FILES:
      return {
        ...state,
        schoolList: {
          ...state.schoolList,
          data:
            action.data === KEEP_THE_SAME ? state.schoolList.data : action.data,
          status: action.status || null,
        },
      };
    // GET_SCHOOL_Data
    case Types.GET_SCHOOL_BASIC_DATA:
      // console.log(action.data);
      return {
        ...state,
        schoolBasicData: {
          ...state.schoolBasicData,
          data:
            action.data === KEEP_THE_SAME
              ? state.schoolBasicData.data
              : action.data,
          status: action.status || null,
        },
      };
    case Types.GET_SCHOOL_TEACHER_LIST:
      return {
        ...state,
        schoolTeachersList: {
          ...state.schoolTeachersList,
          data:
            action.data === KEEP_THE_SAME
              ? state.schoolTeachersList.data
              : action.data,
          status: action.status || null,
        },
      };
    case Types.GET_INDICTMENT_LIST:
      return {
        ...state,
        indictmentList: {
          data:
            action.data === KEEP_THE_SAME
              ? state.indictmentList.data
              : action.data,
          status: action.status || null,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default ApplicationSchoolDetailsReducer;
