//  [REDUCER][TYPE]
import { typeCreatorV2 } from '../../utils/common';

export const SET_MODAL_VISIBILITY: string = typeCreatorV2(
  'ModalReducer',
  'SetModalVisibility'
);

export enum ModalIDs {
  TOAST = 1,
  MODAL_DASHBOARD_ADD_GRAPH = 2,
  MODAL_FIXED_ESTABLISHMENT = 3,
  MODAL_IDENTIFICATION_IN_OTHER_EU_COUNTRIES = 4,
  MODAL_PREVIOUS_USE_OF_SPECIAL_SCHEMES = 5,
  MODAL_DASHBOARD_ELEMENT_CONFIG = 6, // dashboardbuilder_page
  MODAL_DASHBOARD_ELEMENT_REMOVE = 7, // dashboardbuilder_page
  MODAL_DASHBOARD_BUILDER_SETTINGS = 8, // dashboardbuilder_page
  MODAL_DASHBOARD_SAVE = 12, // dashboardbuilder_page
  MODAL_CONFIG_WRAPPER = 10,
  MODAL_FORM_WRAPPER = 11, // forms_page
  // FOR GENERATOR
  MODAL_ADD_EDIT_USER = 9,
  MODAL_APPLICATION_TEACHER_ADD_SERVICE = 10,
  MODAL_APPLICATION_TEACHER_ADD_QUALIFICATION = 11,
  MODAL_APPLICATION_TEACHER_ADD_EDUCATION = 12,
  MODAL_APPLICATION_TEACHER_ADD_WORKING_EXPERIENCE = 13,
  MODAL_ADD_EDIT_SCHOOL_PRINCIPAL = 14,
  MODAL_ADD_EDIT_SCHOOL_ONWER = 15,
  MODAL_APPLICATION_CONFIRM_UPDATE = 16,
  MODAL_FILE_PREVIEW = 17,
  MODAL_COMMUNICATION_DETAIL = 18,
  MODAL_ADD_EDIT_USERS = 19,
  MODAL_ADD_EDIT_VISITS = 20,
  MODAL_ADD_EDIT_SCHOOL_EQUIPMENT = 21,
}
