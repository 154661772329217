interface IOptions {
  statusCode: number;
}

export class ClientError extends Error {
  readonly statusCode?: number;

  constructor(message: string, options?: IOptions) {
    super(message);
    this.statusCode = options?.statusCode;
    this.message = message;
  }
}
