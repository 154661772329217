// eslint-disable-next-line
import React from 'react';
import { SwitchProps, Switch as MuiSwitch } from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';

interface Iprops extends Omit<SwitchProps, 'onChange' | 'checked'> {
  control: Control;
  rules: any;
  name: string;
  label?: string | React.ReactElement;
  controllerExtras?: any;
}

export default function Switch(props: Iprops) {
  const { control, label, rules, controllerExtras = {}, ...other } = props;

  return (
    <section>
      <label>{label}</label>
      <Controller
        name={other.name}
        render={(props) => (
          <MuiSwitch
            {...props}
            {...other}
            onChange={(e) => props.onChange(e.target.checked)}
            checked={props.value}
          />
        )}
        control={control}
        {...controllerExtras}
      />
    </section>
  );
}
