// eslint-disable-next-line
import React, { CSSProperties, useMemo } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import * as NavigationSelectors from '../../../../redux/reducers/gl_navigation_reducer/selectors';
import useStyles from '../styles';
import { useDispatch } from 'react-redux';
import NavigationActions from '../../../../redux/reducers/gl_navigation_reducer/actions';
import { useHistory, useLocation } from 'react-router';
import { getNavbarConfig } from '../../../config/Routes';
import {
  Avatar,
  Box,
  Collapse,
  Hidden,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import * as AuthenticationSelectors from '../../../../redux/reducers/gl_auth_reducer/selectors';
import Configuration from '../configuration';
import * as Paths from '../../../../utils/path';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigationConfig from '../../../config';
import reactLogger from 'utils/logger';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import KeyboardTabIcon from '@material-ui/icons/KeyboardTab';

const SidebarList = () => {
  reactLogger.renderComponent('SidebarList');

  const theme = useTheme();
  const classes = useStyles({});
  const open = NavigationSelectors.useSidebarIsVisible();
  const dispatch = useDispatch();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  React.useLayoutEffect(() => {
    if (matches) {
      dispatch(NavigationActions.setSideMenuVisiblityAction(true));
    } else {
      dispatch(NavigationActions.setSideMenuVisiblityAction(false));
    }
  }, [matches]);

  const toggleDrawer =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      dispatch(NavigationActions.setSideMenuVisiblityAction(false));
    };

  const handleDrawerClose = () => {
    dispatch(NavigationActions.setSideMenuVisiblityAction(false));
  };

  const AppDrawer = React.useMemo(
    () => (
      <React.Fragment>
        {matches && Configuration.sidebar.enable && (
          <Drawer
            className={clsx(classes.drawer)}
            variant="persistent"
            anchor="left"
            classes={{ paper: classes.drawerPaper }}
            PaperProps={{
              elevation: 0,
              style: {
                top: `${theme.spacing(8)}px`,
                height: `calc(100% - ${theme.spacing(8)}px)`,
              },
            }}
            open={open}
          >
            <Content />
          </Drawer>
        )}

        {!matches && (
          <Drawer
            className={clsx(classes.drawer)}
            variant="temporary"
            color="red"
            classes={{ paper: classes.drawerPaper }}
            onClose={toggleDrawer()}
            anchor="left"
            PaperProps={{ elevation: 0 }}
            open={open}
          >
            <div
              // style={{
              //   backgroundColor:
              //     Configuration.sidebar.config?.backgroundColor5 || 'white',
              // }}
              className={classes.drawerHeader}
            >
              <IconButton
                style={{ color: 'inherit' }}
                onClick={handleDrawerClose}
              >
                {theme.direction === 'ltr' ? <CloseIcon /> : <CloseIcon />}
              </IconButton>
            </div>
            <Content />
          </Drawer>
        )}
      </React.Fragment>
    ),
    [open, classes, matches]
  );

  return <React.Fragment>{AppDrawer}</React.Fragment>;
};

interface ContentProps {
  style?: CSSProperties;
}
const Content = React.memo(({ style }: ContentProps) => {
  const history = useHistory();
  const classes = useStyles({});
  const theme = useTheme();
  const dispatch = useDispatch();
  const { username, email } = AuthenticationSelectors.useSelectUserData();

  return (
    <Box style={style}>
      <Box
        // style={{
        //   backgroundColor:
        //     Configuration.sidebar.config?.backgroundColor2 || 'white',
        // }}
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <img
          className={classes.avatar}
          src={Configuration.profile.config?.iconSrc || null}
          onClick={() => history.push(NavigationConfig.mainPage().path)}
        />
        {username && (
          <Typography
            color="textPrimary"
            variant="h5"
            style={{
              textAlign: 'center',
              color: Configuration.sidebar.config?.textColor1,
            }}
          >
            {username}
          </Typography>
        )}
        <Typography
          color="textSecondary"
          variant="body2"
          style={{
            textAlign: 'center',
            color: Configuration.sidebar.config?.textColor1,
          }}
        >
          {email}
        </Typography>
      </Box>
      <Box
        display={'flex'}
        flexDirection="column"
        flex="1"
        // style={{
        //   backgroundColor:
        //     Configuration.sidebar.config?.backgroundColor3 || 'white',
        // }}
      >
        <SidebarListContent />
      </Box>
    </Box>
  );
});

const SidebarListContent = React.memo(() => {
  console.log('Render SidebarListContent');
  const roles = AuthenticationSelectors.useSelectUserRoles();
  const dispatch = useDispatch();
  const history = useHistory();

  // filter items by user role
  const sidebarData = getNavbarConfig(roles);

  return (
    <List>
      {sidebarData.map(
        (
          { title, path, Icon, id, subItems, showOpenInNewWindowIcon },
          index
        ) => (
          <SidebarElement
            key={id}
            title={title}
            path={path}
            Icon={Icon}
            subItems={subItems}
            id={id}
            onClick={() =>
              path &&
              dispatch(
                NavigationActions.navigateToScreenAction(history, path, id)
              )
            }
            showOpenInNewWindowIcon={showOpenInNewWindowIcon}
          />
        )
      )}
    </List>
  );
});

interface ISubItems {
  path: string;
  title: string | React.ReactElement;
  component: React.FC;
  id: number;
  showOpenInNewWindowIcon?: boolean;
}

interface ISubItemProps extends ISubItems {}

interface ISidebarElementProps {
  title: string | React.ReactElement;
  path: string | null;
  subItems?: ISubItems[];
  Icon: React.FC;
  id: number;
  onClick: () => void;
  showOpenInNewWindowIcon?: boolean;
}

const SidebarSubElement = (props: ISubItemProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;
  const isSelected = Paths.pathsIsTheSame(currentPath, props.path);
  const classes = useStyles({ isSelected: isSelected });
  const clickAction = () => {
    dispatch(
      NavigationActions.navigateToScreenAction(history, props.path, props.id)
    );
  };
  return (
    <ListItem
      className={clsx(classes.drawerItem, classes.subElement)}
      onClick={clickAction}
      key={props.id}
    >
      <ListItemText secondary={props.title} />
      {props.showOpenInNewWindowIcon && (
        <IconButton
          size={'small'}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            window.open(props.path);
          }}
        >
          <Tooltip title={'Open in New Tab'} placement={'top'}>
            <KeyboardTabIcon />
          </Tooltip>
        </IconButton>
      )}
    </ListItem>
  );
};

const SidebarElement = (props: ISidebarElementProps) => {
  const { Icon, title, path, onClick, id, subItems } = props;
  const location = useLocation();
  const currentPath = location.pathname;
  const [expand, setExpand] = React.useState<boolean>(false);
  const isSelected = subItems
    ? false
    : path && Paths.pathsIsTheSame(currentPath, path);
  const classes = useStyles({ isSelected: isSelected });

  const expandByPath: boolean = React.useMemo(() => {
    if (subItems) {
      for (let item of subItems) {
        if (Paths.pathsIsTheSame(currentPath, item.path)) {
          return true;
        }
      }
    }
    return false;
  }, [subItems, currentPath]);

  React.useEffect(() => {
    setExpand(expandByPath);
  }, [expandByPath]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{
        // boxSizing: 'border-box',
        overflowX: 'hidden',
      }}
    >
      <ListItem
        className={clsx(
          classes.drawerItem
          // isSelected ? classes.drawerSelectedItem : classes.drawerUnselectedItem
        )}
        onClick={subItems ? () => setExpand(!expand) : onClick}
        button
        key={id}
      >
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText
          classes={{
            secondary: classes.drawerListItemText,
          }}
          secondary={title}
        />
        {subItems ? (
          <ExpandMoreIcon
            className={clsx(classes.expand, {
              [classes.expandOpen]: expand,
            })}
            aria-expanded={expand}
          />
        ) : props.showOpenInNewWindowIcon ? (
          <IconButton
            size={'small'}
            onClick={(event) => {
              if (path) {
                event.preventDefault();
                event.stopPropagation();
                window.open(path);
              }
            }}
          >
            <Tooltip title={'Open in New Tab'} placement={'top'}>
              <KeyboardTabIcon />
            </Tooltip>
          </IconButton>
        ) : (
          <></>
        )}
        <Divider />
      </ListItem>
      {subItems && (
        <Collapse in={expand} timeout="auto" unmountOnExit>
          <ListSubItemsBlock subItems={subItems} />
        </Collapse>
      )}
    </Box>
  );
};

interface ISubItemsProps {
  subItems: ISubItemProps[];
}

const ListSubItemsBlock = React.memo(({ subItems }: ISubItemsProps) => {
  return (
    <List>
      {subItems.map(
        ({ path, title, component, id, showOpenInNewWindowIcon }, index) => (
          <SidebarSubElement
            component={component}
            title={title}
            path={path}
            key={id}
            id={id}
            showOpenInNewWindowIcon={showOpenInNewWindowIcon}
          />
        )
      )}
    </List>
  );
});

export default SidebarList;
