import { ThemeOptions } from '@material-ui/core';
import { ThemesModeEnum } from 'config';
import {
  APP_COLOR_PRIMARY,
  APP_COLOR_SECONDARY,
  TEXT_COLOR_PRIMARY,
} from 'styles/colors';

interface IThemesConfig {
  [ThemesModeEnum.Dark]: ThemeOptions;
  [ThemesModeEnum.Light]: ThemeOptions;
}

export const ThemesConfig: IThemesConfig = {
  [ThemesModeEnum.Dark]: {
    palette: {
      primary: {
        main: APP_COLOR_PRIMARY({ darkMode: true }),
      },
      secondary: {
        main: APP_COLOR_SECONDARY({ darkMode: true }),
      },
      background: {
        default: '#121212',
      },
      text: {
        primary: TEXT_COLOR_PRIMARY({ darkMode: true }),
      },
      type: 'dark',
    },
  },
  [ThemesModeEnum.Light]: {
    palette: {
      primary: {
        main: APP_COLOR_PRIMARY({}),
      },
      secondary: {
        main: APP_COLOR_SECONDARY({}),
      },
      background: {
        default: '#F8F7F7',
      },
      text: {
        primary: TEXT_COLOR_PRIMARY({}),
        // secondary: colors.common['white'],
      },
      type: 'light',
    },
  },
};
