// eslint-disable-next-line
import React, { ReactElement } from 'react';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  RadioGroupProps,
  FormLabel,
  FormHelperText,
} from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';
import useStyles from './styles';

interface IIcon {
  position: 'left' | 'right';
  Component: ReactElement;
}

interface IValues {
  label: any;
  value: any;
}

interface Iprops extends Omit<RadioGroupProps, ''> {
  control: Control;
  rules: any;
  icon?: IIcon;
  label: any;
  name: string;
  values: IValues[];
  helperText: string | undefined;
  error: boolean;
  disabled?: boolean;
  color?: 'primary' | 'secondary';
  controllerExtras?: any;
  margin?: 'none' | 'normal';
}

export default function AppRadioGroup(props: Iprops) {
  const {
    control,
    error,
    helperText,
    label,
    icon,
    rules,
    values,
    disabled,
    margin,
    color,
    controllerExtras = {},
    ...other
  } = props;
  const classes = useStyles({ margin });

  return (
    <Controller
      render={({ value, onChange, ...rest }) => {
        const val = value;
        return (
          <FormControl
            component="fieldset"
            error={error}
            disabled={Boolean(disabled)}
            className={classes.formControl}
          >
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup
              {...other}
              value={value}
              onChange={(event, value) => {
                onChange(event, value);
                if (other.onChange) other.onChange(event, value);
              }}
            >
              {values.map(({ label, value }, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    value={value}
                    control={<Radio color={color || 'primary'} />}
                    label={label}
                    checked={value == val}
                  />
                );
              })}
            </RadioGroup>
            <FormHelperText error>{helperText}</FormHelperText>
          </FormControl>
        );
      }}
      name={other.name}
      control={control}
      rules={rules}
      {...controllerExtras}
    />
  );
}
