import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';
import * as ApplicationService from 'services/api/application';

export interface IState {
  applicationList: {
    data: ApplicationService.IFetchUserApplicationsData | null;
    status: StateStatus | null;
  };
}

const initialState: IState = {
  applicationList: {
    data: null,
    status: null,
  },
};

const ApplicationListReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    // SET_DISTRICTS
    case Types.SET_APPLICATIONS:
      // console.log(action.data);
      return {
        ...state,
        applicationList: {
          ...state.applicationList,
          data:
            action.data === KEEP_THE_SAME
              ? state.applicationList.data
              : action.data,
          status: action.status || null,
        },
      };

    default:
      return {
        ...state,
      };
  }
};

export default ApplicationListReducer;
