import request from '../../../utils/request';
import { Environment } from '../../../config';

interface IHelpMeEmailInput {
  subject: string;
  content: string;
}
export const sendHelpMeEmail = async (
  payload: IHelpMeEmailInput
): Promise<any> => {
  const { data } = await request(
    'post',
    `${Environment.API_BASE_URL}/api/help-me/`,
    { body: payload }
  );
  return data;
};
