import { IAction, KEEP_THE_SAME, StateStatus } from 'redux/utils/common';
import * as Types from './types';

export interface IState {
  ReviewList: {
    status: StateStatus | null;
    data: any;
  };
}

const initializeState: () => IState = () => {
  return {
    ReviewList: {
      status: null,
      data: null,
    },
  };
};

const initialState: IState = initializeState();

const ReviewHistoryReducer = (
  state: IState = initialState,
  action: IAction
): IState => {
  switch (action.type) {
    case Types.SET_REVIEW_LIST:
      return {
        ...state,
        ReviewList: {
          ...state.ReviewList,
          status: action.status || null,
          data:
            action.data === KEEP_THE_SAME ? state.ReviewList.data : action.data,
        },
      };
    case Types.CLEAR_STORE:
      let clearedState: IState = initializeState();
      clearedState = {
        ...clearedState,
      };
      return { ...clearedState };
    default:
      return { ...state };
  }
};

export default ReviewHistoryReducer;
