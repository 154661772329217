import * as Types from './types';
import { Dispatch } from 'redux';
import {
  IAction,
  IActionMethods,
  KEEP_THE_SAME,
  StateStatus,
} from '../../../redux/utils/common';

class SetOpen implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.SET_OPEN,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(open: boolean): IAction {
    return {
      type: Types.SET_OPEN,
      status: StateStatus.Success,
      data: open,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.SET_OPEN,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(open: boolean): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        // dispatch(this.onPending());
        dispatch(this.onSuccess(open));
      } catch (error: any) {
        console.log('Search Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export class ToggleAction implements IActionMethods {
  onPending(): IAction {
    return {
      type: Types.TOGGLE,
      status: StateStatus.Pending,
      data: KEEP_THE_SAME,
    };
  }
  onSuccess(): IAction {
    return {
      type: Types.TOGGLE,
      status: StateStatus.Success,
      data: null,
    };
  }

  onFailed(): IAction {
    return {
      type: Types.TOGGLE,
      status: StateStatus.Failed,
      data: null,
    };
  }

  action(): any {
    return async (dispatch: Dispatch<any>) => {
      try {
        // dispatch(this.onPending());
        dispatch(this.onSuccess());
      } catch (error: any) {
        console.log('Search Error:', error.message); // '<ClassName> Error: <error>'
        dispatch(this.onFailed());
      }
    };
  }
}

export default {
  SetOpenAction: (open: boolean) => new SetOpen().action(open),
  ToggleAction: () => new ToggleAction().action(),
};
