// eslint-disable-next-line
import React, { ReactElement } from 'react';
import {
  MenuItem,
  TextField,
  StandardTextFieldProps,
  InputAdornment,
} from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';
import useStyles from './styles';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import _, { isArray } from 'lodash';
import Chip from '@material-ui/core/Chip';
import { IOption } from '../select';

interface IIcon {
  position: 'left' | 'right';
  Component: ReactElement;
}

interface Iprops
  extends Omit<
    StandardTextFieldProps,
    'onChange' | 'value' | 'variant' | 'name'
  > {
  options: IOption[];
  control: Control;
  rules: any;
  variant: 'standard' | 'outlined' | 'filled';
  icon?: IIcon;
  name: string;
  inputVariant?: 'checkbox';
  controllerExtras?: any;
  multiple?: boolean;
  selectedKey?: string;
  loading?: boolean;
  handleChange?: (data: string) => void;
  handleBlur?: (data: string[]) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
};

export default function MultipleSelect(props: Iprops) {
  const {
    inputVariant,
    control,
    rules,
    options,
    icon,
    controllerExtras = {},
    multiple = true,
    selectedKey = 'label',
    loading = false,
    handleChange,
    handleBlur,
    ...other
  } = props;
  const optionsMap = React.useMemo(() => _.keyBy(options, 'id'), [options]);

  return (
    <Controller
      render={({ onBlur, onChange, value }) => {
        // console.log(value);
        return (
          <TextField
            select
            disabled={loading}
            {...other}
            SelectProps={{
              multiple: multiple,
              value: value || [],
              onChange: (event) => {
                onChange(event);
              },
              onBlur: (event) => {
                onBlur();
                if (handleBlur && Array.isArray(event.target.value))
                  handleBlur(event.target.value);
              },
              MenuProps: MenuProps,
              renderValue: (selected: any) => {
                // console.log(selected);
                return (
                  <div
                    style={{
                      display: 'inline-flex',
                      flexWrap: 'wrap',
                      gap: 6,
                    }}
                  >
                    {isArray(selected) ? (
                      selected.map((_value: any, index: any) => (
                        <Chip
                          key={index}
                          size="medium"
                          label={_.get(optionsMap[_value], selectedKey, '')}
                        />
                      ))
                    ) : (
                      <Chip
                        size="medium"
                        label={_.get(optionsMap[selected], selectedKey, '')}
                      />
                    )}
                  </div>
                );
              },
            }}
            InputProps={{
              ...props.InputProps,
              endAdornment: (
                <React.Fragment>
                  {icon && icon.position === 'right' && (
                    <InputAdornment position="start">
                      {icon.Component}
                    </InputAdornment>
                  )}
                </React.Fragment>
              ),
            }}
          >
            {options.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {inputVariant === 'checkbox' ? (
                  <React.Fragment>
                    <Checkbox
                      checked={Array.isArray(value) && value.includes(item.id)}
                    />
                    <ListItemText primary={_.get(item, selectedKey, '')} />
                  </React.Fragment>
                ) : (
                  _.get(item, selectedKey, '')
                )}
              </MenuItem>
            ))}
          </TextField>
        );
      }}
      name={other.name}
      control={control}
      rules={rules}
      {...controllerExtras}
    />
  );
}
