// eslint-disable-next-line
import React from 'react';
import {
  Box,
  BoxProps,
  Card,
  CardContent,
  CardContentProps,
  CardHeader,
  CardHeaderProps,
  CardProps,
  Divider,
} from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';

export interface IChartLayoutProps {
  layoutProps?: CardProps;
  titleLayoutProps?: CardHeaderProps;
  contentLayoutProps?: CardContentProps;
  children: React.ReactElement;
  onOverview?: () => React.ReactElement;
  title?: any;
  divider?: boolean;
  FooterContent?: React.ReactElement | null;
  footerLayoutProps?: BoxProps;
}

export default function ChartLayout(props: IChartLayoutProps) {
  const {
    divider,
    title,
    onOverview,
    contentLayoutProps = {},
    children,
    titleLayoutProps = {},
    FooterContent = null,
    footerLayoutProps = {},
    layoutProps = {},
  } = props;
  const classes = useStyles({});

  return (
    <Card
      {...layoutProps}
      style={{ padding: 0, ...layoutProps.style }}
      className={clsx(classes.chartContainer, layoutProps?.className)}
    >
      <CardHeader
        action={<React.Fragment>{onOverview && onOverview()}</React.Fragment>}
        title={title}
        {...titleLayoutProps}
        style={{
          paddingLeft: 15,
          paddingRight: 15,
          paddingTop: 10,
          paddingBottom: 0,
          ...titleLayoutProps.style,
        }}
      />
      {divider && <Divider />}
      <CardContent
        {...contentLayoutProps}
        style={{ padding: 10, ...contentLayoutProps.style }}
        className={clsx(
          classes.contentContainer,
          contentLayoutProps?.className
        )}
      >
        {children}
      </CardContent>
      {divider && <Divider />}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        {...footerLayoutProps}
        style={{ padding: 0, ...footerLayoutProps.style }}
      >
        {FooterContent}
      </Box>
    </Card>
  );
}
